/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { config } from 'config';

import { APIService } from 'lib/restful-client/api.service';
import { StorageService } from 'lib/services/storage.service';
import { CacheService } from 'lib/services/cache.service';
import { TokenService } from 'lib/services/token.service';
import { APIConfig } from 'lib/restful-client/APIConfig';

export const apiConfig: APIConfig = new APIConfig(
  config.apiEndPoint || '/api',
  config.apiTimeout || 60000
);

export const storageService: StorageService = new StorageService();
export const tokenService: TokenService = new TokenService(storageService);
export const cacheService: CacheService = new CacheService(storageService);
export const api: APIService = new APIService(
  apiConfig, cacheService, storageService, tokenService
);
/**
 * @file: NavHeader.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import $ from 'jquery';
import { addTransitionEndEvent } from './helpers';
import { getViewportHeight } from 'utils/ViewportSize';

class NavHeaderManager {
  public navHeaderEl: HTMLElement;
  private dropdownEl: HTMLElement | null;
  private dropdownTitleEl: HTMLElement | null;
  private dropdownMenuEl: HTMLElement | null;
  private dropdownMenuContainer: HTMLElement;
  private arrowEl: HTMLElement;

  init() {
    this.navHeaderEl = document.querySelector('.header')! as HTMLElement;
    this.dropdownEl = document.querySelector('.header__dropdown');
    this.dropdownTitleEl = document.querySelector('.header__dropdown-title');
    this.dropdownMenuEl = document.querySelector('.header__dropdown-menu');

    if (this.dropdownEl && this.dropdownMenuEl && this.dropdownTitleEl) {
      this.initDropdown();
    }
  }

  initDropdown() {
    this.dropdownMenuContainer = document.createElement('div');
    this.dropdownMenuContainer.className = 'header__dropdown-menu-ct';
    const parentEl = this.dropdownMenuEl!.parentElement!;
    parentEl.removeChild(this.dropdownMenuEl!);
    parentEl.appendChild(this.dropdownMenuContainer);
    this.dropdownMenuContainer.appendChild(this.dropdownMenuEl!);
    this.arrowEl = document.createElement('div');
    this.arrowEl.className = 'header__dropdown-arrow';
    this.dropdownMenuEl!.style.display = 'block';
    this.dropdownMenuContainer.prepend(this.arrowEl);
    this.dropdownTitleEl!.addEventListener(
      'click', this.onDropdownTitleClick
      );
  }

  onDropdownTitleClick = (e: Event) => {
    e.preventDefault();
    const containerEl = this.dropdownMenuContainer;
    containerEl.style.opacity = '0';
    containerEl.style.display = 'block';
    const titleWidth = this.dropdownEl!.offsetWidth;
    const menuWidth = this.dropdownMenuEl!.offsetWidth;
    const arrowWidth = this.arrowEl.offsetWidth;
    const arrowPosX = menuWidth / 2 - arrowWidth / 2;
    this.arrowEl.style.left = `${arrowPosX}px`;
    containerEl.style.top = `${this.dropdownEl!.offsetHeight + arrowWidth}px`;
    containerEl.style.left = `${(titleWidth - menuWidth)/2}px`;

    const hide = () => {
      // hide the mneu
      document.body.removeEventListener('click', onDocumentClick);
      containerEl.style.opacity = '0';
      addTransitionEndEvent(containerEl, () => {
        containerEl.style.display = 'none';
      });
    };

    const onDocumentClick = (event: Event) => {
      let el = event.target as HTMLElement;
      while (el) {
        if (el.classList.contains('header__dropdown-menu-item')) {
          console.log('menu item clicked');
        }
        el = el.parentElement as HTMLElement;
      }

      hide();
    };
    addTransitionEndEvent(containerEl, () => {
      console.log('transition end');
      setTimeout(() => {
        document.body.addEventListener('click', onDocumentClick);
      }, 0);
    });
    setTimeout(() => {
      containerEl.style.opacity = '1';
    }, 0);
  }
}

const navHeaderManager = new NavHeaderManager();

$(function() {
  navHeaderManager.init();
  const emptyListPlaceholder: HTMLElement | null = document.querySelector('.empty-list-placeholder');
  if (emptyListPlaceholder) {
    const height = getViewportHeight() - navHeaderManager.navHeaderEl.offsetHeight - 30;
    emptyListPlaceholder.style.height = `${height}px`;
  }
});
/**
 * @file: MediatorDeviceStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum MediatorDeviceStatus {
  InStock = 'instock',
  StockOutPending = 'stock_out_pending',
  Delivering = 'delivering',
  Onboard = 'onboard',
  Faulted = 'faulted',
  Maintaining = 'maintaining',
  Scrapped = 'scrapped',
  Recycled = 'recycled'
}
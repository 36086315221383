import './index.scss';
import { withListItemClickable } from 'lib/ui';

export function home() {
  withListItemClickable(
    document.querySelector('.user-home__vehicle-list') as any,
    'user-home__vehicle',
    '.user-home__accessory-view > a'
    );

  withListItemClickable(
    document.querySelector('.user-home__report-list') as any,
    'user-home__report',
    '.user-home__accessory-view > a'
    );

  withListItemClickable(
    document.querySelector('.user-home__order-list') as any,
    'user-home__order',
    '.user-home__accessory-view > a'
    );
}
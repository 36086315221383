/**
 * @file: InspectionTaskEventSubType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum InspectionTaskEventSubType {
  LicensePlateNoCaptured = 101,
  VinCaptured = 102,
  OrderCreated = 103,
  TaskCreated = 104,
  TaskBasicInfoUpdated = 105,
  InspectionStarted = 201,
  ItemInspected = 202,
  SiteInspectionStarted = 203,
  SiteInspectionFinished = 204,
  SiteInspectionCancelled = 205,
  CustomIssueAdded = 206,
  CustomIssueUpdated = 207,
  InspectionFinished = 208,
  InspectionReportGenerated = 209,
  InspectionReportUpdated = 210,
  InspectionReportShared = 211,
  QuotationStarted = 301,
  QuotationInitiated = 302,
  QuotationSubmitted = 303,
  QuotationConfirmed = 304,
  QuotationFinished = 305,
  QuotationPrinted = 306,
  QuotationUpdated = 307,
  QuotationReportGenerated = 308,
  QuotationReportUpdated = 309,
  QuotationReportShared = 310,
  ConstructionConfirmed = 401,
  ConstructionUpdated = 402,
  ConstructionJobBeginned = 403,
  ConstructionJobCommitted = 404,
  ConstructionJobCommittedUpdated = 405,
  ConstructionFinished = 406,
  ConstructionReportGenerated = 407,
  ConstructionReportUpdated = 408,
  ConstructionReportShared = 409,
  DiagnosticJobListUpdated = 501,
  DiagnosticJobBeginned = 502,
  DiagnosticJobCommitted = 503,
  DiagnosticJobCommittedUpdated = 504,
  DiagnosticFinished = 505,
  DiagnosticReportGenerated = 506,
  DiagnosticReportUpdated = 507,
  DiagnosticReportShared = 508,
  PendingIssuesConfirmed = 601,
  CheckListItemReviewed = 602,
  CheckListReviewed = 603,
  DeliveryCheckReportGenreated = 604,
  DeliveryCheckReportUpdated = 605,
  DeliveryCheckReportShared = 606,
  Done = 606,
  Closed = 607,
  PreInspectionStarted = 701,
  PreSiteInspectionStarted = 702,
  PreItemInspected = 703,
  PreSiteInspected = 704,
  PreInspectionFinished = 705,
  PreInspectionReportGenerated = 706,
  PreInspectionReportUpdated = 707,
  PreInspectionReportShared = 708
}

export const InspectionTaskEventSubTypeValueSet = new Set([
  InspectionTaskEventSubType.LicensePlateNoCaptured,
  InspectionTaskEventSubType.VinCaptured,
  InspectionTaskEventSubType.OrderCreated,
  InspectionTaskEventSubType.TaskCreated,
  InspectionTaskEventSubType.TaskBasicInfoUpdated,
  InspectionTaskEventSubType.InspectionStarted,
  InspectionTaskEventSubType.ItemInspected,
  InspectionTaskEventSubType.SiteInspectionStarted,
  InspectionTaskEventSubType.SiteInspectionFinished,
  InspectionTaskEventSubType.SiteInspectionCancelled,
  InspectionTaskEventSubType.CustomIssueAdded,
  InspectionTaskEventSubType.CustomIssueUpdated,
  InspectionTaskEventSubType.InspectionFinished,
  InspectionTaskEventSubType.InspectionReportGenerated,
  InspectionTaskEventSubType.InspectionReportUpdated,
  InspectionTaskEventSubType.InspectionReportShared,
  InspectionTaskEventSubType.QuotationStarted,
  InspectionTaskEventSubType.QuotationInitiated,
  InspectionTaskEventSubType.QuotationSubmitted,
  InspectionTaskEventSubType.QuotationConfirmed,
  InspectionTaskEventSubType.QuotationFinished,
  InspectionTaskEventSubType.QuotationPrinted,
  InspectionTaskEventSubType.QuotationUpdated,
  InspectionTaskEventSubType.QuotationReportGenerated,
  InspectionTaskEventSubType.QuotationReportUpdated,
  InspectionTaskEventSubType.QuotationReportShared,
  InspectionTaskEventSubType.ConstructionConfirmed,
  InspectionTaskEventSubType.ConstructionUpdated,
  InspectionTaskEventSubType.ConstructionJobBeginned,
  InspectionTaskEventSubType.ConstructionJobCommitted,
  InspectionTaskEventSubType.ConstructionJobCommittedUpdated,
  InspectionTaskEventSubType.ConstructionFinished,
  InspectionTaskEventSubType.ConstructionReportGenerated,
  InspectionTaskEventSubType.ConstructionReportUpdated,
  InspectionTaskEventSubType.ConstructionReportShared,
  InspectionTaskEventSubType.DiagnosticJobListUpdated,
  InspectionTaskEventSubType.DiagnosticJobBeginned,
  InspectionTaskEventSubType.DiagnosticJobCommitted,
  InspectionTaskEventSubType.DiagnosticJobCommittedUpdated,
  InspectionTaskEventSubType.DiagnosticFinished,
  InspectionTaskEventSubType.DiagnosticReportGenerated,
  InspectionTaskEventSubType.DiagnosticReportUpdated,
  InspectionTaskEventSubType.DiagnosticReportShared,
  InspectionTaskEventSubType.PendingIssuesConfirmed,
  InspectionTaskEventSubType.CheckListItemReviewed,
  InspectionTaskEventSubType.CheckListReviewed,
  InspectionTaskEventSubType.DeliveryCheckReportGenreated,
  InspectionTaskEventSubType.DeliveryCheckReportUpdated,
  InspectionTaskEventSubType.DeliveryCheckReportShared,
  InspectionTaskEventSubType.Done,
  InspectionTaskEventSubType.Closed,
  InspectionTaskEventSubType.PreInspectionStarted,
  InspectionTaskEventSubType.PreSiteInspectionStarted,
  InspectionTaskEventSubType.PreItemInspected,
  InspectionTaskEventSubType.PreSiteInspected,
  InspectionTaskEventSubType.PreInspectionFinished,
  InspectionTaskEventSubType.PreInspectionReportGenerated,
  InspectionTaskEventSubType.PreInspectionReportUpdated,
  InspectionTaskEventSubType.PreInspectionReportShared
]);
/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';
import { withSelectEffect, toast } from 'lib/ui';
import { InfiniteScroll } from 'lib/ui/InfiniteScroll';
import { serviceAgentService } from 'services';
import { spin, unspin } from 'shared';

class SaOrderListManager {
  private orderListEl: HTMLElement;
  // @ts-ignore
  private infiniteScroll: InfiniteScroll;
  private limit: number;
  private offset: number = 0;
  private count: number = 0;
  private hasMore: boolean = false;

  init() {
    this.orderListEl = document.querySelector('.infinite-scroll-list')! as HTMLElement;
    if (this.orderListEl) {
      this.limit = Number(this.orderListEl.getAttribute('data-limit'));
      this.count = this.orderListEl.querySelectorAll('.saorder').length;
      this.hasMore = this.count >= this.limit;
      this.infiniteScroll = new InfiniteScroll(this.orderListEl, {
        onLoadMore: this.onLoadMore
      });
      this.orderListEl.addEventListener('click', this.onListClick);
    }
  }

  onListClick = (e: Event) => {
    // find the saorder element
    let el = e.target as HTMLElement;
    while (el && !el.classList.contains('saorder') && el !== this.orderListEl) {
      el = el.parentElement!;
    }
    if (!el || el === this.orderListEl) return;
    e.preventDefault();
    const linkEl = el.querySelector('.saorder__accessory-view > a')! as HTMLAnchorElement;
    const url = linkEl.href;
    console.log(url);
    withSelectEffect(el, () => {
      location.href = url;
    });
  }

  onLoadMore = async () => {
    if (!this.hasMore) {
      toast('没有更多了');
      return;
    }
    spin();
    try {
      const result = await serviceAgentService.renderOrderList(
        this.offset + this.limit, this.limit
        );
      unspin();
      this.orderListEl.insertAdjacentHTML('beforeend', result);
      const count = this.orderListEl.querySelectorAll('.saorder').length;
      const fetchedCount = count - this.count;
      if (fetchedCount < this.limit) {
        this.hasMore = false;
      }
      this.count = count;
      this.offset += fetchedCount;
      if (!fetchedCount) {
        toast('没有更多了');
      }
    } catch (e) {
      unspin();
      toast('获取接车记录列表失败');
    }
  }
}

export const saOrderListManager = new SaOrderListManager();

export function saorderlist() {
  saOrderListManager.init();
}
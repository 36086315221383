/**
 * @file: toast.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { getViewportWidth, getViewportHeight } from 'utils/ViewportSize';
import { addTransitionEndEvent } from './helpers';

export function toast(msg: string) {
  const w = getViewportWidth();
  const h = getViewportHeight();

  const el = document.createElement('div');

  el.innerHTML = msg;

  el.style.position = 'fixed';
  el.style.opacity = '0';
  el.style.maxWidth = `${w * 0.6}px`;
  el.style.fontSize = '0.85rem';
  el.style.color = '#eee';
  el.style.padding = '8px 15px';
  el.style.backgroundColor = 'rgb(95, 95, 95)';
  el.style.transition = 'all 0.3s cubic-bezier(0.22, 1, 0.36, 1)';
  el.style.webkitTransition = 'all 0.3s cubic-bezier(0.22, 1, 0.36, 1)';
  el.style.boxShadow = '0 0 16px #999';
  el.style.webkitBoxShadow = '0 0 16px #999';
  el.style.zIndex = '1000';

  document.body.appendChild(el);

  const height = el.offsetHeight;
  const width = el.offsetWidth;

  el.style.left = `${(w - width) / 2}px`;
  el.style.borderRadius = `${height / 2}px`;
  el.style.bottom = `${-height}px`;

  el.style.webkitTransform = 'translate3d(0, 0, 0)';
  el.style.transform = 'translate3d(0, 0, 0)';

  setTimeout(() => {
    const offset = h * 0.3;

    addTransitionEndEvent(el, () => {
      // hide toast after some delay
      setTimeout(() => {
        addTransitionEndEvent(el, () => {
          el.parentNode?.removeChild(el);
        });
        el.style.opacity = '0';
      }, 1000);
    });

    el.style.webkitTransform = `translate3d(0, -${offset}px, 0)`;
    el.style.transform = `translate3d(0, -${offset}px, 0)`;
    el.style.opacity = '1';
  }, 0);
}
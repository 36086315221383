import './index.scss';

import { AbstractListManager } from 'lib/ui/AbstractListManager';
import { userService } from 'services';

interface Filter {
  vin?: string;
  licensePlateNo?: string;
}

class ReportListManager extends AbstractListManager<Filter> {
  constructor() {
    super('report', '车检报告');
  }

  fetch(offset: number, limit: number, filter: Filter): Promise<string> {
    return userService.renderReportList(offset, limit, filter);
  }
}

export const reportListManager = new ReportListManager();

export function reportlist() {
  reportListManager.init();
}
/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export * from './AdminUser';
export * from './AdminUserAcl';
export * from './Area';
export * from './MediatorDevice';
export * from './MediatorDeviceActionLog';
export * from './MediatorDeviceManufacturer';
export * from './MediatorDeviceOnboardStatus';
export * from './OrgMember';
export * from './OrgUser';
export * from './OrgUserAcl';
export * from './Organization';
export * from './Store';
export * from './SysErrorLog';
export * from './SystemConfigurationItem';
export * from './User';
export * from './UserFormId';
export * from './VehicleInfo';
export * from './VehicleInspectionSite';
export * from './VehicleInspectionSiteCategory';
export * from './VehicleInspectionSiteCheckItem';
export * from './VehicleInspectionSiteCheckItemMedia';
export * from './VehicleInspectionSiteCheckItemOption';
export * from './VehicleInspectionSiteMaintenancePeriod';
export * from './VehicleInspectionSiteMaintenancePeriodVariance';
export * from './VehicleInspectionTask';
export * from './VehicleInspectionTaskCheckSite';
export * from './VehicleInspectionTaskCheckSiteItem';
export * from './VehicleInspectionTaskCheckSiteItemData';
export * from './VehicleInspectionTaskCheckSiteItemMedia';
export * from './VehicleOwner';
export * from './VehicleReceptionOrder';
export * from './VehicleReceptionOrderTechnician';
export * from './IdSeedRange';
export * from './VehicleInspectionComment';
export * from './VehicleInspectionReport';
export * from './VehicleInspectionReportDetail';
export * from './VehicleInspectionReportDetailData';
export * from './Customer';
export * from './CustomerVehicle';
export * from './MediatorDeviceGodownEntry';
export * from './MediatorDeviceGodownEntryDetail';
export * from './MediatorDeviceOutboundDeliveryOrder';
export * from './MediatorDeviceOutboundDeliveryOrderDetail';
export * from './MediatorDevicePurchaseOrder';
export * from './StoreCustomer';
export * from './InspectionTool';
export * from './VehicleInspectionTemplate';
export * from './VehicleInspectionTemplateSite';
export * from './VehicleInspectionTemplateSiteItem';
export * from './OrgUserPermission';
export * from './OrgUserPermissionCategory';
export * from './OrgUserRole';
export * from './OrgUserRolePermissionRel';
export * from './OrgUserRoleRel';
export * from './OrgUserWeixinBinding';
export * from './UserWeixinBinding';
export * from './VehicleInspectionTaskAssignment';
export * from './VehicleInspectionReportDetailMedia';
export * from './VehicleInspectionTaskSelectedSite';
export * from './VehicleIssue';
export * from './VehicleInspectionSiteRel';
export * from './OrgGroup';
export * from './OrgTeam';
export * from './VehicleInspectionTaskTroubleCode';
export * from './VehicleInspectionTaskCustomIssue';
export * from './VehicleInspectionTaskCustomIssueMedia';
export * from './VehicleInspectionSubject';
export * from './VehicleServiceSubject';
export * from './VehicleInspectionTaskEvent';
export * from './VehicleInspectionTaskJob';
export * from './VehicleInspectionTaskJobDetail';
export * from './VehicleInspectionTaskJobMedia';
export * from './VehicleInspectionTaskDiagnosticJob';
export * from './VehicleInspectionTaskDiagnosticJobMedia';
export * from './VehicleInspectionTaskDiagnosticJobTroubleCode';
export * from './VehicleInspectionTaskSubject';
export * from './VehicleInspectionTaskIssueRef';
export * from './VehicleInspectionReportUpdateLog';
export * from './UserVehicleBinding';
export * from './StoreMedia';
export * from './VehicleInspectionTaskQuotation';
export * from './VehicleInspectionTaskQuotationDetail';
export * from './QuotationPartCatalog';
export * from './AutoPartName';
export * from './VehicleInspectionTaskQuotationIssue';
export * from './VehicleInspectionTaskDeliveryCheck';
export * from './VehicleInspectionTaskDeliveryCheckMedia';
export * from './VehicleInspectionTaskQuotationForm';
export * from './Vehicle';
/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';

export function saorderdetail() {
  const urls: string[] = [];
  document.querySelectorAll('.saorder__preview-img-btn').forEach(el => {
    const url = el.getAttribute('data-url')!;
    urls.push(url);
    el.addEventListener('click', e => {
      e.preventDefault();
      wx.previewImage({
        current: url,
        urls
      });
    });
  });
}
import './index.scss';
import { AbstractListManager } from 'lib/ui/AbstractListManager';

class VehicleListManager extends AbstractListManager {
  constructor() {
    super('vehicle', '车辆');
  }

  fetch(): Promise<string> { return Promise.resolve(''); }
}

export const vehicleListManager = new VehicleListManager();

export function vehiclelist() {
  vehicleListManager.init();
}
/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { api } from 'lib';
import { SmsService } from './sms.service';
import { OrganizationService } from './org.service';
import { VehicleInfoService } from './vehicle.service';
import { ServiceAgentService } from './sa.service';
import { UserService } from './user.service';

export const smsService = new SmsService(api);
export const orgService = new OrganizationService(api);
export const vehicleInfoService = new VehicleInfoService(api);
export const serviceAgentService = new ServiceAgentService(api);
export const userService = new UserService(api);
/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';
import $ from 'jquery';
import qs from 'qs';
import { ActionSheet } from 'lib/ui/ActionSheet';
import {
  Organization,
  Store,
  Gender,
  OrgUserSignupInfo,
  OrgUserRoleType,
} from 'model';
import { orgService, smsService } from 'services';
import { inputAllowNumbersOnly, withSelectEffect } from 'lib/ui/helpers';
import { isMobile } from 'utils/validators';
import { spin, unspin } from 'shared/spinkit';
import { sha256 } from 'utils';
import { HttpError } from 'lib/restful-client/HttpError';
// import { MobileSelect } from 'lib/ui';

const kDefaultAppDownloadUrl =
  'http://appstore.zhichetech.com/downpage/ade2bc76544a42d0';

const Roles = [
  { id: OrgUserRoleType.ServiceAgents, value: '服务顾问' },
  { id: OrgUserRoleType.Technicians, value: '服务技师' },
  {
    id: OrgUserRoleType.Manager,
    value: '门店经理',
    description: '可接收门店业绩报告推送',
  },
];

type RoleType = Exclude<OrgUserRoleType, OrgUserRoleType.Administrator>;

class SignupManager {
  private orgEl: HTMLElement;
  private storeEl: HTMLElement;
  private userRoleEl: HTMLElement;
  private userNameEl: HTMLInputElement;
  private nameEl: HTMLInputElement;
  private nickEl: HTMLInputElement;
  private passwordEl: HTMLInputElement;
  private mobileEl: HTMLInputElement;
  private codeEl: HTMLInputElement;
  private sendButton: HTMLButtonElement;
  private submitButton: HTMLButtonElement;
  private originalSendBtnText: string;
  private key: string;
  private scene: string;
  private openId: string;
  private counter: number = 0;
  private keyword: string = '';
  private continueUrl: string = '';
  private role: string;
  private organizations: Organization[] = [];
  private stores: Store[] = [];
  private isLoadingStores = false;
  private loadStoresError: Error | null = null;
  private selectedOrgId: number | null = null;
  private selectedStoreId: number | null = null;
  private storeListEl: HTMLElement | null = null;
  private orgPickerActionSheet: ActionSheet | null = null;
  private storePickerActionSheet: ActionSheet | null = null;
  private rolePickerActionSheet: ActionSheet | null = null;
  // private userRolePicker: MobileSelect;

  init() {
    this.orgEl = document.getElementById('orgId')! as HTMLElement;
    if (!this.orgEl) {
      this.selectedOrgId = Number(
        (
          document.querySelector(
            'input[name="data-org-id"]'
          )! as HTMLInputElement
        ).value
      );
    }
    this.storeEl = document.getElementById('storeId')! as HTMLElement;
    this.userRoleEl = document.getElementById('userRole')! as HTMLElement;
    this.userNameEl = document.getElementById('userName')! as HTMLInputElement;
    this.nameEl = document.getElementById('name')! as HTMLInputElement;
    this.nickEl = document.getElementById('nick')! as HTMLInputElement;
    this.passwordEl = document.getElementById('password')! as HTMLInputElement;
    this.mobileEl = document.getElementById('mobile')! as HTMLInputElement;
    this.codeEl = document.getElementById('verifyCode')! as HTMLInputElement;
    this.sendButton = document.getElementById('sendCode')! as HTMLButtonElement;
    this.submitButton = document.getElementById(
      'btn-submit'
    )! as HTMLButtonElement;
    this.originalSendBtnText = this.sendButton.innerText;
    this.key = this.value('#key');
    this.scene = this.value('#scene');
    this.openId = this.value('#openid');
    this.role = this.value<RoleType>('#role') || '';

    this.updateRoles();

    const query = qs.parse(location.search.substr(1));
    this.continueUrl =
      query.continue_url || query.continueUrl || '/sa/signup-success.html';

    const storeId = this.value('#storeIdField', (x) => Number(x || '0'));
    const storeName = this.value('#storeName');
    if (storeId && storeName) {
      this.selectedStoreId = storeId;
      this.storeEl.classList.remove('form__picker-field--empty');
    }

    this.initEvents();
  }

  updateRoles() {
    const roles = this.role
      .split(',')
      .filter((x) => x)
      .map((role) => Roles.find((x) => x.id === role))
      .filter((x) => x);
    if (roles.length) {
      this.userRoleEl.innerHTML = roles.map((x) => x!.value).join(',');
      this.userRoleEl.classList.remove('form__picker-field--empty');
    } else {
      this.userRoleEl.innerHTML = '请选择用户类型';
      this.userRoleEl.classList.add('form__picker-field--empty');
    }
  }

  value<T = string>(selector: string, conv?: (value: string) => T): T {
    const el = document.querySelector(selector) as HTMLInputElement;
    const v = el.value || '';
    if (conv) return conv(v);
    return v as unknown as T;
  }

  initEvents() {
    if (this.orgEl) {
      this.orgEl.addEventListener('click', this.showOrgPicker);
      $(this.orgEl)
        .next('div')[0]
        .addEventListener('click', this.showOrgPicker);
    }

    this.storeEl.addEventListener('click', this.showStorePicker);
    $(this.storeEl)
      .next('div')[0]
      .addEventListener('click', this.showStorePicker);

    this.userRoleEl.addEventListener('click', this.showRolePicker);
    $(this.userRoleEl)
      .next('div')[0]
      .addEventListener('click', this.showRolePicker);

    $(this.passwordEl)
      .next('div')[0]
      .addEventListener('click', this.onToggleRevealPassword);

    inputAllowNumbersOnly(this.mobileEl);
    inputAllowNumbersOnly(this.codeEl);

    this.mobileEl.addEventListener('keyup', (e) => {
      const el = e.target as HTMLInputElement;
      this.sendButton.disabled = !isMobile(el.value);
    });

    this.mobileEl.addEventListener('change', (e) => {
      const el = e.target as HTMLInputElement;
      this.sendButton.disabled = !isMobile(el.value);
    });

    this.sendButton.addEventListener('click', this.onSendButtonClick);

    this.key = (document.getElementById('key')! as HTMLInputElement).value;
    this.scene = (document.getElementById('scene')! as HTMLInputElement).value;

    this.submitButton.addEventListener('click', this.onSubmit);
  }

  onSubmit = async (e: Event) => {
    e.preventDefault();

    const signupInfo = this.getSignupInfo();
    if (!signupInfo) return;

    try {
      spin();
      const res = await orgService.register(signupInfo, this.continueUrl);
      const redirectUrl = typeof res === 'string' ? res : res.redirectUrl;
      const appDownloadUrl =
        typeof res === 'string' || !res.appDownloadUrl
          ? kDefaultAppDownloadUrl
          : res.appDownloadUrl;
      unspin();
      console.log(redirectUrl);
      if (confirm('注册成功，是否现在下载知车智检APP?')) {
        location.href = appDownloadUrl;
      } else {
        location.href = redirectUrl;
      }
    } catch (e) {
      unspin();
      this.handleRegisterError(e);
    }
  };

  handleRegisterError(error: HttpError) {
    console.error(error);

    if (error.subCode === 'weixin_reauth_required') {
      location.href = this.continueUrl;
      return;
    }

    let errMsg: string = '';
    switch (error.subCode) {
      case 'missing_verify_code':
        errMsg = '请输入手机验证码!';
        break;
      case 'missing_role':
        errMsg = '请选择用户类型!';
        break;
      case 'verify_code_expired':
        errMsg = '验证码已过期，请重新获取验证码!';
        break;
      case 'invalid_verify_code':
        errMsg = '验证码不正确!';
        break;
      case 'user_exists':
        errMsg = '手机号码已存在';
        break;
      case 'max_user_account_limit_exceeded':
        errMsg =
          '对不起，贵门店已注册用户数已达到或超过系统支持的最大注册用户数，请联系我们为您升级以便继续使用相关服务！谢谢您的支持！';
        break;
      default:
        errMsg = '对不起，注册失败，请稍候重试!';
        break;
    }
    alert(errMsg);
  }

  getSignupInfo(): OrgUserSignupInfo | undefined {
    if (!this.selectedOrgId) {
      alert('请选择您所在企业!');
      return;
    }

    if (!this.selectedStoreId) {
      alert('请选择您所在门店!');
      return;
    }

    const genderMaleEl = document.getElementById(
      'gender-male'
    )! as HTMLInputElement;

    const userName = this.userNameEl.value.trim();
    const password = this.passwordEl.value.trim();
    const name = this.nameEl.value.trim();
    const mobile = this.mobileEl.value.trim();
    const verifyCode = this.codeEl.value.trim();
    const nick = this.nickEl.value.trim();
    const gender = genderMaleEl.checked ? Gender.Male : Gender.Female;
    const role = this.role;

    if (!role) {
      alert('请选择注册门店用户类型!');
      return;
    }

    // if (!userName) {
    //   alert('请输入您的登录用户名!');
    //   return;
    // }

    // if (!/[0-9a-z]{3,20}/i.test(userName)) {
    //   alert('用户名必须是至少3位最多20位的数字和字母的组合!');
    //   return;
    // }

    if (!name || name.length < 2) {
      alert('请输入正确的姓名!');
      return;
    }

    if (!password) {
      alert('请输入登录密码!');
      return;
    }

    if (password.length < 6 || password.length > 32) {
      alert('登录密码必须包含至少6个最多32个字符!');
      return;
    }

    if (!mobile) {
      alert('请输入您的手机号码!');
      return;
    }

    if (!isMobile(mobile)) {
      alert('请输入正确的手机号码!');
      return;
    }

    if (!verifyCode) {
      alert('请输入手机验证码!');
      return;
    }

    const signupInfo: OrgUserSignupInfo = {
      key: this.key,
      openId: this.openId,
      orgId: this.selectedOrgId,
      storeId: this.selectedStoreId,
      userName,
      password: sha256(password)!,
      name,
      mobile,
      verifyCode,
      nick,
      gender,
      role,
    };

    console.log(signupInfo);

    return signupInfo;
  }

  onSendButtonClick = async () => {
    spin();
    this.sendButton.disabled = true;
    try {
      this.counter = await smsService.sendVerifyCode(
        this.mobileEl.value,
        this.key,
        this.scene
      );
      unspin();
      this.countDown();
    } catch (e) {
      console.error(e);
      this.sendButton.disabled = false;
      alert('发送验证失败，请稍后重试!');
      unspin();
    }
  };

  onToggleRevealPassword = (e: Event) => {
    e.preventDefault();
    if (this.passwordEl.type === 'password') {
      this.passwordEl.type = 'text';
    } else {
      this.passwordEl.type = 'password';
    }
  };

  onOrgSelected = (orgId: number) => {
    const org = this.organizations!.find((x) => x.id === orgId)!;
    this.orgEl.innerHTML = org.name;
    this.orgEl.classList.remove('form__picker-field--empty');
    this.orgPickerActionSheet?.dismiss();
    if (this.selectedOrgId !== orgId) {
      this.selectedOrgId = orgId;
      this.storeEl.innerHTML = this.storeEl.getAttribute('data-placeholder')!;
      this.storeEl.classList.add('form__picker-field--empty');
      this.isLoadingStores = false;
      this.loadStoresError = null;
      this.stores = [];
    }
  };

  onStoreSelected = (storeId: number) => {
    this.selectedStoreId = storeId;
    const store = this.stores?.find((x) => x.id === this.selectedStoreId);
    this.storeEl.innerHTML = store?.name || '';
    this.storeEl.classList.remove('form__picker-field--empty');
    this.storePickerActionSheet?.dismiss();
  };

  countDown = () => {
    if (!this.counter) {
      this.sendButton.disabled = false;
      this.sendButton.innerText = this.originalSendBtnText;
      return;
    }
    this.sendButton.innerText = `${this.counter}秒后重新获取`;
    this.counter--;
    setTimeout(this.countDown, 1000);
  };

  renderOrgPickerContents() {
    return [
      '<div class="sa-signup__org-picker-contents">',
      '  <h5>搜索并选择你所在企业</h5>',
      '  <form>',
      '    <div class="form-group">',
      '      <div class="input-group mb-2">',
      '        <input type="text" class="form-control sa-signup__org-keyword" placeholder="输入关键词搜索您所在企业" />',
      '        <div class="input-group-append">',
      '          <button type="button" class="btn btn-primary sa-signup__btn-search-org" disabled>搜索</button>',
      '        </div>',
      '      </div>',
      '    </div>',
      '    <div class="form-group">',
      '      <div class="sa-signup__org-list"></div>',
      '    </div>',
      '  </form>',
      '</div>',
    ].join('');
  }

  onOrgPickerRendered = (containerEl: HTMLElement) => {
    const orgListEl = containerEl.querySelector(
      '.sa-signup__org-list'
    )! as HTMLDivElement;
    const keywordEl = containerEl.querySelector(
      '.sa-signup__org-keyword'
    )! as HTMLInputElement;
    const searchBtn = containerEl.querySelector(
      '.sa-signup__btn-search-org'
    )! as HTMLButtonElement;

    keywordEl.value = this.keyword;
    searchBtn.disabled = this.keyword.length < 2;

    keywordEl.addEventListener('keyup', () => {
      const keyword = keywordEl.value.trim();
      searchBtn.disabled = keyword.length < 2;
    });

    orgListEl.addEventListener('click', (e) => {
      let el = e.target as HTMLElement | null;
      while (
        el &&
        !el.classList.contains('sa-signup__org-list') &&
        !el.classList.contains('sa-signup__org')
      ) {
        el = el.parentElement;
      }
      if (el?.classList.contains('sa-signup__org')) {
        const orgId = Number(el.getAttribute('data-id'));
        withSelectEffect(el!, () => {
          this.onOrgSelected(orgId);
        });
      }
    });

    searchBtn.addEventListener('click', async () => {
      searchBtn.disabled = true;
      searchBtn.innerHTML =
        '<i class="icon-spinner3 icon-pulse"></i> 搜索中...';
      try {
        this.keyword = keywordEl.value.trim();
        this.organizations = await orgService.search(this.keyword);
        orgListEl.innerHTML = this.renderOrgList();
      } catch (e) {
        orgListEl.innerHTML = this.renderOrgList(e);
      } finally {
        searchBtn.innerHTML = '搜索';
        searchBtn.disabled = false;
      }
    });

    orgListEl.innerHTML = this.renderOrgList();
  };

  renderStorePickerContents() {
    return [
      '<div class="sa-signup__store-picker-contents">',
      '  <h5>选择你所在门店</h5>',
      '  <div class="sa-signup__store-list"></div>',
      '</div>',
    ].join('');
  }

  onStorePickerRendered = (containerEl: HTMLElement) => {
    this.storeListEl = containerEl.querySelector(
      '.sa-signup__store-list'
    )! as HTMLElement;

    this.storeListEl.addEventListener('click', (e) => {
      let el = e.target as HTMLElement | null;
      while (
        el &&
        !el.classList.contains('sa-signup__store-list') &&
        !el.classList.contains('sa-signup__store')
      ) {
        el = el.parentElement;
      }
      if (el?.classList.contains('sa-signup__store')) {
        const storeId = Number(el.getAttribute('data-id'));
        withSelectEffect(el!, () => {
          this.onStoreSelected(storeId);
        });
      }
    });
    this.loadStores();
  };

  renderOrgList(error?: Error | null) {
    if (error) {
      console.error(error);
      return [
        '<div class="sa-signup__search-error">',
        `  <div>搜索企业时发生一个错误, 请稍候重试! <p class="mb-0"><code>${error.message}</code></p></div>`,
        '</div>',
      ].join('');
    }
    if (!this.organizations.length) {
      if (!this.keyword) {
        return '<div class="text-muted sa-signup__empty-list">暂无相关搜索结果</div>';
      } else {
        return [
          '<div class="text-muted sa-signup__empty-list">',
          `  <div>对不起，没有查询到与<code>${this.keyword}</code>相关的企业，请重新输入关键词查询。</div>`,
          '</div>',
        ].join('');
      }
    } else {
      return this.organizations
        .map((org) => {
          return [
            `<div class="sa-signup__org" data-id="${org.id}">`,
            `  <div class="sa-signup__org-title mb-2">`,
            `    <h6 class="mt-0 mb-0 mr-3 sa-signup__org-name">${org.name}</h6>`,
            `    <img src="${org.logoImgUrl}" alt="${org.brandName}" class="sa-signup__org-logo" />`,
            '  </div>',
            `  <div class="sa-signup__org-detail">`,
            `    <p class="mb-0 sa-signup__org-intro text-muted">${
              org.introduction?.substr(0, 80) + '...'
            }</p>`,
            '    <i class="icon-arrow-right"></i>',
            '  </div>',
            '</div>',
          ].join('');
        })
        .join('');
    }
  }

  renderStoreList() {
    if (!this.storeListEl) return;
    this.storeListEl.innerHTML = this.renderStoreListHtml();
  }

  renderStoreListHtml() {
    if (this.isLoadingStores) {
      return [
        '<div class="text-muted sa-signup__empty-list">',
        '  <div><i class="icon-spinner3 icon-pulse"></i> 正在获取门店列表，请稍候...</div>',
        '</div>',
      ].join('');
    }
    if (this.loadStoresError) {
      return [
        '<div class="sa-signup__search-error">',
        `  <div>获取门店列表时发生一个错误, 请稍候重试! <p class="mb-0"><code>${this.loadStoresError.message}</code></p></div>`,
        '</div>',
      ].join('');
    }
    if (!this.stores.length) {
      const org = this.organizations.find((x) => x.id === this.selectedOrgId);
      return [
        '<div class="text-muted sa-signup__empty-list">',
        `  <div>对不起，该企业<code>${
          org && org.name
        }</code>暂无相关门店可选择</div>`,
        '</div>',
      ].join('');
    }
    return this.stores
      .map((store) => {
        return [
          `<div class="sa-signup__store" data-id="${store.id}">`,
          `  <div class="sa-signup__store-detail">`,
          `    <div class="sa-signup__store-title mb-2">`,
          `      <h6 class="mt-0 mb-0 mr-3 sa-signup__store-name">${store.name}</h6>`,
          '    </div>',
          `    <p class="mb-0 sa-signup__store-addr text-muted">${store.address}</p>`,
          store.introduction
            ? `    <p class="mb-0 sa-signup__store-intro text-muted">${
                store.introduction?.substr(0, 40) + '...'
              }</p>`
            : '',
          '  </div>',
          '  <i class="icon-arrow-right"></i>',
          '</div>',
        ].join('');
      })
      .join('');
  }

  showOrgPicker = () => {
    this.orgPickerActionSheet = new ActionSheet({
      contents: this.renderOrgPickerContents(),
      noPadding: true,
      onRender: this.onOrgPickerRendered,
      onDismissed: () => {
        this.orgPickerActionSheet = null;
      },
    });

    this.orgPickerActionSheet.present();
  };

  showStorePicker = () => {
    if (!this.selectedOrgId) return;

    this.storePickerActionSheet = new ActionSheet({
      contents: this.renderStorePickerContents(),
      noPadding: true,
      onRender: this.onStorePickerRendered,
      onDismissed: () => {
        this.storePickerActionSheet = null;
      },
    });

    this.storePickerActionSheet.present();
  };

  async loadStores() {
    if (!this.selectedOrgId) return;
    try {
      this.isLoadingStores = true;
      this.loadStoresError = null;
      this.renderStoreList();
      this.stores = await orgService.getStoresByOrgId(this.selectedOrgId);
    } catch (e) {
      this.loadStoresError = e;
    } finally {
      this.isLoadingStores = false;
      this.renderStoreList();
    }
  }

  showRolePicker = () => {
    if (!this.selectedOrgId) return;

    this.rolePickerActionSheet = new ActionSheet({
      contents: this.renderRolePickerContents(),
      noPadding: true,
      onRender: this.onRolePickerRendered,
      onDismissed: () => {
        this.rolePickerActionSheet = null;
      },
    });

    this.rolePickerActionSheet.present();
  };

  renderRolePickerContents() {
    const style = 'color:gray;font-weight:normal;font-size: 1rem;';
    return [
      '<div class="sa-signup__role-picker-contents">',
      `  <h5>`,
      `    <span>选择用户角色 <span style="${style}">(可选择多个角色)</span></span>`,
      `    <a href="#" class="sa-signup__role-picker-done">完成</a>`,
      `  </h5>`,
      '  <div class="sa-signup__role-list">',
      ...Roles.map((role) =>
        [
          '<div class="sa-signup__role-list-item-wrap">',
          `<div class="sa-signup__role-list-item" data-value="${role.id}">`,
          `  <div>${role.value}</div>`,
          '  <i class="icon-checkmark" style="display:none;"></i>',
          '</div>',
          role.description
            ? [
                `<div class="sa-signup__role-list-item-desc">${role.description}</div>`,
              ].join('')
            : '',
          '</div>',
        ].join('\n')
      ),
      '  </div>',
      '</div>',
    ].join('');
  }

  onRolePickerRendered = (containerEl: HTMLElement) => {
    const storeListEl = containerEl.querySelector(
      '.sa-signup__role-list'
    )! as HTMLElement;

    const itemElements = storeListEl.querySelectorAll(
      '.sa-signup__role-list-item'
    );

    const doneEl = containerEl.querySelector(
      '.sa-signup__role-picker-done'
    ) as HTMLElement;

    doneEl?.addEventListener('click', (e) => {
      e.preventDefault();
      this.rolePickerActionSheet?.dismiss();
    });

    const getItemInfo = (el: HTMLElement) => {
      const role = el.getAttribute('data-value')! as OrgUserRoleType;
      const checkmark = el.querySelector('.icon-checkmark')! as HTMLElement;
      return { role, checkmark };
    };
    itemElements.forEach((el: HTMLElement) => {
      const { role, checkmark } = getItemInfo(el);
      const rolelist = this.role.split(',').filter((x) => x) || [];
      if (rolelist.includes(role)) {
        checkmark.style.display = '';
      }
      el.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault();
        const roles = (this.role.split(',').filter((x) => x) ||
          []) as OrgUserRoleType[];
        const idx = roles.indexOf(role);
        if (idx >= 0) {
          roles.splice(idx, 1);
        } else {
          roles.push(role);
        }
        itemElements.forEach((x) => {
          const itemInfo = getItemInfo(x as HTMLElement);
          if (roles.includes(itemInfo.role)) {
            itemInfo.checkmark.style.display = '';
          } else {
            itemInfo.checkmark.style.display = 'none';
          }
        });
        this.role = roles.join(',');
        this.updateRoles();
        withSelectEffect(el.parentElement as any, () => {
          console.log(role);
        });
      });
    });
  };
}

export function sasignup() {
  const manager = new SignupManager();

  manager.init();
}

import { APIService } from 'lib/restful-client/api.service';
import { OrgUserLoginInfo, VehicleReceptionOrder, VehicleInfo, PlaceOrderResult } from 'model';

export class ServiceAgentService {
  constructor(private api: APIService) {
  }

  async login(loginInfo: OrgUserLoginInfo): Promise<string> {
    return await this.api.post()
      .url('/sa/login')
      .data(loginInfo)
      .future();
  }

  async placeOrder(
    orderInfo: Partial<VehicleReceptionOrder>,
    vehicleInfo?: Partial<VehicleInfo>
  ): Promise<PlaceOrderResult> {
    return await this.api.post()
      .url('/sa/orders')
      .data({ orderInfo, vehicleInfo })
      .future();
  }

  async renderOrderList(offset: number, limit: number, filter?: any): Promise<string> {
    return await this.api.get()
      .url('/sa/orders', { offset, limit, ...(filter || {}) })
      .responseType('text')
      .future();
  }

  async renderTaskList(offset: number, limit: number, filter?: any): Promise<string> {
    return await this.api.get()
      .url('/sa/tasks', { offset, limit, ...(filter || {}) })
      .responseType('text')
      .future();
  }

  async renderReportList(offset: number, limit: number, filter?: any): Promise<string> {
    return await this.api.get()
      .url('/sa/reports', { offset, limit, ...(filter || {}) })
      .responseType('text')
      .future();
  }
}
/**
 * @file: InspectionTemplatePredefinedType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum InspectionTemplatePredefinedType {
  FullInspection = 'full_inspection',
  AirConditionerInspection = 'air_conditioner_inspection',
  CarWashBeauty = 'car_wash_beauty',
  Other = 'other'
}
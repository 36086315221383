/**
 * @file: org.service.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import { Organization, Store, OrgUserSignupInfo } from 'model';

export class OrganizationService {
  constructor(private api: APIService) {}

  async search(keyword: string): Promise<Organization[]> {
    return await this.api.get().url('/org/search', { keyword }).future();
  }

  async getStoresByOrgId(orgId: number): Promise<Store[]> {
    return await this.api.get().url('/org/:orgId/stores', { orgId }).future();
  }

  async register(
    signupInfo: OrgUserSignupInfo,
    continueUrl: string
  ): Promise<
    | string
    | {
        redirectUrl: string;
        appDownloadUrl?: string;
      }
  > {
    return await this.api
      .post()
      .url('/org/:orgId/stores/:storeId/users', {
        orgId: signupInfo.orgId,
        storeId: signupInfo.storeId,
        continueUrl,
      })
      .data(signupInfo)
      .future();
  }
}

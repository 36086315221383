/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';
import { userService } from 'services';
import { AbstractListManager } from 'lib/ui/AbstractListManager';

interface Filter {
  vin?: string;
  licensePlateNo?: string;
}

class OrderListManager extends AbstractListManager<Filter> {
  constructor() {
    super('order', '订单记录');
  }
  
  fetch(offset: number, limit: number, filter: Filter): Promise<string> {
    return userService.renderOrderList(offset, limit, filter);
  }
}

export const orderListManager = new OrderListManager();

export function orderlist() {
  orderListManager.init();
}
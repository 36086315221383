/**
 * @file: spinkit.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export function spin() {
  const el = document.querySelector('.spinkit') as HTMLElement;
  el && (el.style.display = 'flex');
}

export function unspin() {
  const el = document.querySelector('.spinkit') as HTMLElement;
  el && (el.style.display = 'none');
}
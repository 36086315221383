/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export * from './common';
export * from './entity';
export * from './enum';
export * from './filter';
export * from './viewmodel';

export type ColorType = 'brand' | 'metal' | 'primary' | 'success' | 'info' |
'warning' | 'danger' | 'focus' | 'accent' | 'default' | 'secondary';

export const DEFAULT_TAG_STYLE = 1;

export const NO_RESPONSE_TIME = -1;

export type StatsPartitionType = 'hour' | 'day' | 'week' | 'month' | 'quarter' |
  'year';

export type ReviewActionType = 'accept' | 'reject';
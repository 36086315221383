/**
 * @file: ActionSheet.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { addTransitionEndEvent, escapeHtml, suspendIosFocusFix, resumeIosFocusFix } from './helpers';

export interface ActionSheetTitleBarOptions {
  title?: string;
  cancelButtonText?: string;
  commitButtonText?: string;
}

interface ActionSheetOptions {
  contents: HTMLElement | string;
  titleBar?: ActionSheetTitleBarOptions;
  autoDismiss?: boolean;
  noPadding?: boolean;
  onRender?: (containerEl: HTMLDivElement) => void;
  onPresented?: (containerEl: HTMLDivElement) => void;
  onDismissed?: () => void;
  onCommit?: (containerEl: HTMLDivElement) => void;
  onCancel?: (containerEl: HTMLDivElement) => void;
}

export class ActionSheet {
  private backdropEl: HTMLDivElement;
  private containerEl: HTMLDivElement;
  // @ts-ignore
  private titleBarEl?: HTMLDivElement;
  constructor(private options: ActionSheetOptions) {
    this.render();
  }

  render() {
    this.backdropEl = document.createElement('div');
    this.backdropEl.classList.add('actionsheet__backdrop');
    this.containerEl = document.createElement('div');
    this.containerEl.classList.add('actionsheet');
    if (this.options.noPadding === true) {
      this.containerEl.classList.add('actionsheet--no-padding');
    }
    if (typeof this.options.contents === 'string') {
      this.containerEl.innerHTML = this.options.contents;
    } else {
      this.containerEl.appendChild(this.options.contents);
    }
    if (this.options.titleBar) {
      const { cancelButtonText, commitButtonText, title } = this.options.titleBar;
      const titleBar = document.createElement('div');
      titleBar.classList.add('actionsheet__title-bar');
      titleBar.innerHTML = [
        `<a href="#" class="actionsheet__title-cancel-btn">${escapeHtml(cancelButtonText || '')}</a>`,
        `<div class="actionsheet__title">${escapeHtml(title || '')}</div>`,
        `<a href="#" class="actionsheet__title-commit-btn">${escapeHtml(commitButtonText || '')}</a>`
      ].join('');
      this.containerEl.prepend(titleBar);
      this.titleBarEl = titleBar;
      const commitBtn = this.containerEl.querySelector('.actionsheet__title-commit-btn')! as HTMLElement;
      const cancelBtn = this.containerEl.querySelector('.actionsheet__title-cancel-btn')! as HTMLElement;
      commitBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.options.onCommit && this.options.onCommit(this.containerEl);
      });
      cancelBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.options.onCancel && this.options.onCancel(this.containerEl);
      });
    }
    document.body.appendChild(this.backdropEl);
    document.body.appendChild(this.containerEl);
    this.options.onRender && this.options.onRender(this.containerEl);
  }

  onBackdropClick = () => {
    this.backdropEl.removeEventListener('click', this.onBackdropClick);
    this.dismiss();
  }

  present() {
    const height = this.containerEl.offsetHeight;
    const style = getComputedStyle(this.containerEl);
    const paddingTop = parseInt(style.paddingTop, 10) || 0;
    const paddingBottom = parseInt(style.paddingBottom, 10) || 0;
    const padding = paddingTop + paddingBottom;

    addTransitionEndEvent(this.backdropEl, () => {
      document.body.style.overflowY = 'hidden';
      this.backdropEl.classList.remove('actionsheet__backdrop--open-enter');
      this.backdropEl.classList.add('actionsheet__backdrop--open');
      if (this.options.autoDismiss !== false) {
        this.backdropEl.addEventListener('click', this.onBackdropClick);
      }
      this.options.onPresented && this.options.onPresented(this.containerEl);
    });

    suspendIosFocusFix();

    this.backdropEl.style.display = 'block';
    setTimeout(() => {
      this.backdropEl.classList.add('actionsheet__backdrop--open-enter');

      // show the action sheet contents
      const styleAttr = document.body.style.transform !== undefined ? 'transform' : 'webkitTransform';
      this.containerEl.style[styleAttr] = `translateY(-${height + padding}px)`;
    }, 0);
  }

  dismiss(callback?: () => void) {
    resumeIosFocusFix();
    addTransitionEndEvent(this.backdropEl, () => {
      const infiniteScroll = (window as any).__infiniteScroll__;
      if (infiniteScroll) {
        infiniteScroll.invalidate();
      }
      document.body.style.overflowY = 'auto';
      this.backdropEl.style.display = 'none';
      document.body.removeChild(this.backdropEl);
      document.body.removeChild(this.containerEl);
      this.backdropEl = null as any;
      this.containerEl = null as any;
      this.options.onDismissed && this.options.onDismissed();
      callback && callback();
    });
    this.backdropEl.classList.remove('actionsheet__backdrop--open');
    const styleAttr = document.body.style.transform !== undefined ? 'transform' : 'webkitTransform';
    this.containerEl.style[styleAttr] = `translateY(0px)`;
  }
}
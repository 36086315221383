/**
 * @file index.ts
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

export interface AppConfig {
  apiEndPoint: string;
  apiTimeout?: number;
  wsEndPoint: string;
}

let _config: AppConfig; // tslint:disable-line

if (process.env.NODE_ENV === 'production') {
  _config = require('./config.production').default;
} else if (process.env.NODE_ENV === 'stage') {
  _config = require('./config.stage').default;
} else {
  _config = require('./config.development').default;
}

export const config = _config;
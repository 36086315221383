/**
 * @file: InspectionReportStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum InspectionReportStatus {
  None = 'none',
  PreWaiting = 'pre_waiting',
  Pre = 'pre',
  PostWaiting = 'post_waiting',
  Post = 'post',
  Done = 'done'
}

export const InspectionReportStatusValueSet = new Set([
  InspectionReportStatus.None,
  InspectionReportStatus.PreWaiting,
  InspectionReportStatus.Pre,
  InspectionReportStatus.PostWaiting,
  InspectionReportStatus.Post,
  InspectionReportStatus.Done
]);
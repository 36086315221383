/**
 * @file: TemplateMessageSceneType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum TemplateMessageSceneType {
  ServiceUpdated = 'service_updated',
  ServiceFinished = 'service_finished'
}
import './index.scss';

import { AbstractListManager } from 'lib/ui/AbstractListManager';
import { serviceAgentService } from 'services';
import { InspectionOrderType } from 'model';
import { ActionSheet, addFormFieldsBehaviors } from 'lib/ui';
import qs from 'qs';
import { snakeCase } from 'change-case';

interface Filter {
  scope?: 'self' | 'store';
  reportNo: string;
  orderType: InspectionOrderType;
  vin: string;
  licensePlateNo: string;
  brand: string;
  customerName: string;
  saName: string;
  techName: string;
}

const OrderTypes = [
  { value: InspectionOrderType.Basic, text: '基础保养服务' },
  { value: InspectionOrderType.Full, text: '全面保养服务' },
  { value: InspectionOrderType.Repair, text: '维修服务' }
];

class ReportListManager extends AbstractListManager<Filter> {
  private searchEl: HTMLElement;
  private searchActionSheet: ActionSheet | null;

  constructor() {
    super('report', '车检报告');
  }

  onInit() {
    this.searchEl = document.querySelector('.header__search')! as HTMLElement;
    this.searchEl.addEventListener('click', this.onSearchButtonClick);
  }

  fetch(offset: number, limit: number, filter: Filter): Promise<string> {
    const filterTransformed: any = {};
    for (const key of Object.keys(filter)) {
      filterTransformed[snakeCase(key)] = filter[key];
    }
    return serviceAgentService.renderReportList(offset, limit, filterTransformed);
  }

  onSearchButtonClick = (e: Event) => {
    e.preventDefault();
    this.searchActionSheet = new ActionSheet({
      noPadding: true,
      contents: this.renderSearchActionSheetContents(),
      titleBar: {
        cancelButtonText: '返回',
        commitButtonText: '搜索',
        title: '搜索条件'
      },
      onRender: (containerEl: HTMLElement) => {
        const getField = <T extends HTMLElement = HTMLInputElement>(name: string): T => {
          return containerEl.querySelector(`[name="${name}"]`)! as T;
        };
        getField('report-no').value = this.filter.reportNo || '';
        getField('order-type').value = this.filter.orderType || '';
        getField('vin').value = this.filter.vin || '';
        getField('license-plate-no').value = this.filter.licensePlateNo || '';
        getField('brand').value = this.filter.brand || '';
        getField('customer-name').value = this.filter.customerName || '';
        getField('sa-name').value = this.filter.saName || '';
        getField('tech-name').value = this.filter.techName || '';

        addFormFieldsBehaviors(containerEl);
      },
      onDismissed: () => {
        this.searchActionSheet = null;
      },
      onCommit: (containerEl: HTMLElement) => {
        const getFieldValue = <T extends HTMLInputElement & HTMLSelectElement = any>(name: string): string | undefined => {
          return (containerEl.querySelector(`[name="${name}"]`)! as T).value || undefined;
        };
        this.filter.reportNo = getFieldValue('report-no');
        this.filter.orderType = getFieldValue('order-type') as InspectionOrderType || undefined;
        this.filter.vin = getFieldValue('vin');
        this.filter.licensePlateNo = getFieldValue('license-plate-no');
        this.filter.brand = getFieldValue('brand');
        this.filter.customerName = getFieldValue('customer-name');
        this.filter.saName = getFieldValue('sa-name');
        this.filter.techName = getFieldValue('tech-name');

        this.searchActionSheet?.dismiss(() => {
          const query = qs.parse(location.search.substr(1));
          for (const name of Object.keys(this.filter)) {
            query[snakeCase(name)] = this.filter[name];
          }
          const queryString = qs.stringify(query);
          const url = [location.pathname, queryString].filter(x => x).join('?');
          location.href = url;
        });
      },
      onCancel: () => {
        this.searchActionSheet?.dismiss();
      }
    });
    this.searchActionSheet.present();
  }

  renderSearchActionSheetContents() {
    return [
      '<form class="form sa-search-form" onsubmit="return false">',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">服务类型: </label>',
      '    <select class="form__field" name="order-type">',
      '      <option value="">全部服务类型</option>',
      ...OrderTypes.map(x => `<option value="${x.value}">${x.text}</option>`),
      '    </select>',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">报告编号: </label>',
      '    <input type="text" class="form__field" name="report-no" placeholder="按报告编号搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">车牌号: </label>',
      '    <input type="text" class="form__field" name="license-plate-no" placeholder="按车牌号搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">车架号: </label>',
      '    <input type="text" class="form__field" name="vin" placeholder="按VIN码搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">车型/品牌: </label>',
      '    <input type="text" class="form__field" name="brand" placeholder="按车型或品牌搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">客户姓名: </label>',
      '    <input type="text" class="form__field" name="customer-name" placeholder="按客户姓名搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">服务顾问: </label>',
      '    <input type="text" class="form__field" name="sa-name" placeholder="按服务顾问名字搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">服务技师: </label>',
      '    <input type="text" class="form__field" name="tech-name" placeholder="按服务技师名字搜索" />',
      '  </div>',
      '</form>'
    ].join('');
  }
}

export const saReportListManager = new ReportListManager();

export function sareportlist() {
  saReportListManager.init();
}
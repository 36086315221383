/**
 * @file: OutboundDeliveryOrderStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum OutboundDeliveryOrderStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Delivering = 'delivering',
  Delivered = 'delivered',
  PartialDelivered = 'partial_delivered'
}
/**
 * @file: PurchaseOrderStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export const enum PurchaseOrderStatus {
  Pending = 'pending',
  Purchasing = 'purchasing',
  Cancelled = 'cancelled',
  Finished = 'finished'
}
/**
 * @file: helpers.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export interface TakePictureResult {
  localId: string;
  serverId?: string;
  imageData?: string;
}

export async function takePicture(): Promise<TakePictureResult | null> {
  return new Promise((resolve, reject) => {
    wx.chooseImage({
      count: 1,
      sizeType: 'compressed',
      sourceType: ['camera'],
      success: function(res: any) {
        if (!/ok/.test(res.errMsg)) {
          resolve(null);
          return;
        }
        const localId = res.localIds[0];
        if (!(wx as any).getLocalImgData) {
          wx.uploadImage({
            localId,
            isShowProgressTips: true,
            success: (res: any) => {
              if (!/ok/.test(res.errMsg)) {
                resolve(null);
                return;
              }
              const serverId = res.serverId;
              resolve({ localId, serverId });
            },
            fail: (res: any) => {
              reject(new Error(res.errMsg));
            }
          });
        } else {
          (wx as any).getLocalImgData({
            localId,
            success: (res: any) => {
              if (!/ok/.test(res.errMsg)) {
                resolve(null);
                return;
              }
              let imageData = res.localData as string;
              if (imageData.indexOf('data:image') < 0) {
                imageData = 'data:image/jpeg;base64,' +  imageData
              }
              imageData = imageData.replace(/\r|\n/g, '').replace('data:image/jgp', 'data:image/jpeg');
              resolve({ localId, imageData });
            },
            fail: (res: any) => {
              reject(new Error(res.errMsg));
            }
          });
        }
      }
    });
  });
}
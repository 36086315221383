import { APIService } from 'lib/restful-client/api.service';
import { UserLoginInfo } from 'model';

export class UserService {
  constructor(private api: APIService) {
  }

  async login(loginInfo: UserLoginInfo): Promise<string> {
    return await this.api.post()
      .url('/login')
      .data(loginInfo)
      .future();
  }

  async renderOrderList(
    offset: number, 
    limit: number,
    filter?: any
    ): Promise<string> {
    return await this.api.get()
      .url('/orders', { offset, limit, ...(filter || {}) })
      .responseType('text')
      .future();
  }

  async renderReportList(offset: number, limit: number, filter?: any): Promise<string> {
    return await this.api.get()
      .url('/reports', { offset, limit, ...(filter || {}) })
      .responseType('text')
      .future();
  }
}
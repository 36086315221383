/**
 * @file ApiServiceHelper
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */
import {HttpError} from 'lib/restful-client/HttpError';

export const CONTENT_TYPE = 'Content-Type';

export const ERROR_STATUS_TXT_MAP: { [code: number]: string; } = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I\'m a teapot',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'No Response',
  494: 'Request header too large',
  495: 'SSL Certificate Error',
  496: 'SSL Certificate Required',
  497: 'HTTP Request Sent to HTTPS Port',
  499: 'Client Closed Request',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required'
};

// resolve content type header from the given headers collection.
export function resolveContentTypeHeader(
  headers: { [name: string]: string } | undefined | null
) {
  if (!headers) return CONTENT_TYPE;
  for (const p in headers) {
    if (headers.hasOwnProperty(p) &&
      /^content-type$/i.test(p)
    ) {
      return p;
    }
  }
  return CONTENT_TYPE;
}

// parse http response data.
export function parseResponseData(response: any) {
  let result = response.data;
  if (typeof result === 'string') {
    try {
      const temp = JSON.parse(result);
      if (temp && (
          temp.hasOwnProperty('response') ||
          temp.hasOwnProperty('code')
        )) {
        result = temp;
      }
    } catch (e) {
      /* noop */
    }
  }
  return result;
}

// make http response error info object.
export function makeError(
  result: { code?: string; subCode?: string, msg?: string; message?: string, response?: any },
  response: { status: number; statusText: string; }
) {
  const { code, subCode, msg, message } = result;
  const err = new HttpError(msg || message);
  err.code = code || 'unknown';
  err.subCode = subCode;
  err.status = response.status;
  err.statusText = response.statusText;
  err.response = result.response;
  return err;
}

export function transformFormUrlEncodedData(obj: object) {
  const formData: string[] = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      let value = obj[p];
      if (void 0 === value || null === value) {
        value = '';
      }
      formData.push(
        encodeURIComponent(p) +
        '=' +
        encodeURIComponent(value)
      );
    }
  }

  return formData.join('&');
}

export function appendQueryString(url: string, name: string, value: any) {
  const index = url.indexOf('?');
  if (index < 0) url += '?';
  else if (index < url.length) url += '&';
  url += `${name}=${encodeURIComponent(value)}`;
  return url;
}

const REQUEST_MTEHODS = {
  get: 0,
  post: 1,
  put: 2,
  delete: 3,
  options: 4,
  head: 5,
  patch: 6
};

export function requestMethodFromStr(method: string): string {
  return REQUEST_MTEHODS[method.toLowerCase()];
}

export function joinPath(...paths: string[]) {
  return paths.reduce((result, path) => {
    if (!result) return path;
    if (!path) return result;
    return result.replace(/\/+$/, '') + '/' + path.replace(/^\/+/, '');
  }, '');
}

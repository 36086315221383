/**
 * @file: Aside.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import $ from 'jquery';

class AsideManager {
  private triggerEl: HTMLElement;
  private backdropEl: HTMLElement;
  private asideEl: HTMLElement;
  private mainEl: HTMLElement;
  private width: number;

  init() {
    this.triggerEl = document.querySelector('.header__menu-trigger')! as HTMLElement;
    this.asideEl = document.querySelector('.aside')! as HTMLElement;
    this.mainEl = document.querySelector('.main')! as HTMLElement;

    this.backdropEl = document.createElement('div');
    this.backdropEl.classList.add('aside__backdrop');
    this.backdropEl.style.display = 'none';
    document.body.append(this.backdropEl);

    this.width = this.asideEl.offsetWidth;
    this.asideEl.style.width = `${this.width}px`;
    this.asideEl.style.left = `-${this.width}px`;

    this.initEvents();
  }

  initEvents() {
    this.triggerEl.addEventListener('click', e => {
      e.preventDefault();
      this.show();
    });

    this.backdropEl.addEventListener('click', () => {
      this.hide();
    });
  }

  show() {
    this.backdropEl.style.display = 'block';
    const transform = `translate3d(${this.width}px, 0, 0)`;
    this.mainEl.style.transform = transform;
    this.mainEl.style.webkitTransform = transform;
    this.asideEl.style.transform = transform;
    this.asideEl.style.webkitTransform = transform;
    document.body.style.overflow = 'hidden';
  }

  hide() {
    this.backdropEl.style.display = 'none';
    const transform = `translate3d(0, 0, 0)`;
    (this.mainEl.style as any).transform = null;
    (this.mainEl.style as any).webkitTransform = null;
    this.asideEl.style.transform = transform;
    this.asideEl.style.webkitTransform = transform;
    document.body.style.overflow = 'auto';
  }
}

export const aside = new AsideManager();

$(function() {
  if (document.querySelector('.aside')) {
    aside.init();
  }
});
/**
 * @file: vehicle.service.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInfo, OcrImageInfo, LicensePlateNoRecognizeInfo } from 'model';

export interface VinRecognizeResult {
  result: string | null;
  fileName: string;
}

export interface LicensePlateNoRecognizeResult {
  result: LicensePlateNoRecognizeInfo | null;
  fileName: string;
}

export class VehicleInfoService {
  constructor(private api: APIService) {
  }

  async vinRecognize(img: OcrImageInfo): Promise<VinRecognizeResult> {
    return this.api.post()
      .url('/vehicle-infos/ocr/vin')
      .data(img)
      .future();
  }

  async licensePlateNoRecognize(img: OcrImageInfo): Promise<LicensePlateNoRecognizeResult> {
    return this.api.post()
      .url('/vehicle-infos/ocr/plateno')
      .data(img)
      .future();
  }

  async getVinInfo(vin: string): Promise<Partial<VehicleInfo>> {
    return this.api.get()
      .url('/vehicle-infos/vin-info', { vin })
      .future();
  }
}
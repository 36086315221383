/**
 * @file: ContentType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum ContentType {
  Text = 1,
  Photo,
  Video,
  Audio
}
/**
 * @file: InspectionTaskEventDataType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum InspectionTaskEventDataType {
  None = 1,
  Text,
  Json,
  Xml,
  Plist,
  Csv,
  StringList,
  MediaList,
  QueryString,
  Custom
}
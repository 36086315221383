/**
 * @file: OfficialAccountMenuItemType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum OfficialAccountMenuItemType {
  Click = 'click',
  View = 'view',
  ScanCodePush = 'scancode_push',
  ScanCodeWaitMsg = 'scancode_waitmsg',
  PicSysPhoto = 'pic_sysphoto',
  PicPhotoOrAlbum = 'pic_photo_or_album',
  PicWeixin = 'pic_weixin',
  LocationSelect = 'location_select',
  Miniprogram = 'miniprogram'
}
/**
 * @file: OfficialAccountCertificateType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum OfficialAccountCertificateType {
  Uncertified = -1,
  WeixinCertified = 0,
  SinaWeiboCertified = 1,
  TencentWeiboCertified = 2,
  QualificationCertifiedWithoutNameCertified = 3,
  QualificationCertifiedWithoutNameCertifiedWithSinaWeiboCertified = 4,
  QualificationCertifiedWithoutNameCertifiedWithTencentWeiboCertified = 5
}
/**
 * @file: isVIN.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

const VIN_REGEX = /^[ABCDEFGHJKLMNPRSTUVWXYZ1234567890]{17}$/;

export function isVIN(s: string) {
  return VIN_REGEX.test(s);
}
/**
 * @file: sms.service.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';

export class SmsService {
  constructor(private api: APIService) {
  }

  async sendVerifyCode(mobile: string, key: string, scene: string): Promise<number> {
    return await this.api.post()
      .url('/sms/verify-code')
      .data({ mobile, key, scene })
      .future();
  }
}
/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';
import qs from 'qs';
import { isMobile } from 'utils/validators';
import { spin, unspin } from 'shared';
import { smsService, serviceAgentService } from 'services';
import { inputAllowNumbersOnly } from 'lib/ui/helpers';
import { OrgUserLoginInfo } from 'model';
import { HttpError } from 'lib/restful-client/HttpError';

class SigninManager {
  private mobileEl: HTMLInputElement;
  private codeEl: HTMLInputElement;
  private sendButton: HTMLButtonElement;
  private submitButton: HTMLButtonElement;
  private key: string;
  private scene: string;
  private openId: string;
  private continueUrl: string = '';
  private counter: number = 0;
  private originalSendBtnText: string;

  init() {
    this.mobileEl = document.getElementById('mobile')! as HTMLInputElement;
    this.codeEl = document.getElementById('code')! as HTMLInputElement;
    this.sendButton = document.getElementById('sendCode')! as HTMLButtonElement;
    this.submitButton = document.getElementById(
      'btn-submit'
    )! as HTMLButtonElement;
    this.originalSendBtnText = this.sendButton.innerText;

    this.key = this.value('#key');
    this.scene = this.value('#scene');
    this.openId = this.value('#openid');

    const query = qs.parse(location.search.substr(1));
    this.continueUrl = query.continue_url || '/sa/login-success.html';

    this.initEvents();
  }

  value<T = string>(selector: string, conv?: (value: string) => T): T {
    const el = document.querySelector(selector) as HTMLInputElement;
    const v = el.value || '';
    if (conv) return conv(v);
    return v as unknown as T;
  }

  initEvents() {
    inputAllowNumbersOnly(this.mobileEl);
    inputAllowNumbersOnly(this.codeEl);

    this.mobileEl.addEventListener('keyup', (e) => {
      const el = e.target as HTMLInputElement;
      this.sendButton.disabled = !isMobile(el.value);
    });

    this.mobileEl.addEventListener('change', (e) => {
      const el = e.target as HTMLInputElement;
      this.sendButton.disabled = !isMobile(el.value);
    });

    this.sendButton.addEventListener('click', this.onSendButtonClick);

    this.submitButton.addEventListener('click', this.onLogin);
  }

  onLogin = async (e: Event) => {
    e.preventDefault();

    const loginInfo = this.getLoginInfo();
    if (!loginInfo) return;

    try {
      spin();
      const redirectUrl = await serviceAgentService.login(loginInfo);
      unspin();
      location.href = redirectUrl;
    } catch (e) {
      unspin();
      this.handleLoginError(e);
    }
  };

  handleLoginError(error: HttpError) {
    console.error(error);

    if (error.subCode === 'weixin_reauth_required') {
      location.href = this.continueUrl;
      return;
    }

    let errMsg: string = '';

    switch (error.subCode) {
      case 'user_not_found':
        errMsg = '该手机号码尚未注册!';
        break;
      case 'missing_verify_code':
        errMsg = '请输入手机验证码!';
        break;
      case 'verify_code_expired':
        errMsg = '验证码已过期，请重新获取验证码!';
        break;
      case 'invalid_verify_code':
        errMsg = '验证码不正确!';
        break;
      case 'multi_weixin_bound_is_not_allowed':
        errMsg = '对不起，当前微信已经绑定了其他用户，不能重复绑定!';
        break;
      default:
        errMsg = '对不起，登录失败，请稍候重试!';
        break;
    }

    alert(errMsg);
  }

  onSendButtonClick = async () => {
    spin();
    this.sendButton.disabled = true;
    try {
      this.counter = await smsService.sendVerifyCode(
        this.mobileEl.value,
        this.key,
        this.scene
      );
      unspin();
      this.countDown();
    } catch (e) {
      console.error(e);
      this.sendButton.disabled = false;
      alert('发送验证失败，请稍后重试!');
      unspin();
    }
  };

  countDown = () => {
    if (!this.counter) {
      this.sendButton.disabled = false;
      this.sendButton.innerText = this.originalSendBtnText;
      return;
    }
    this.sendButton.innerText = `${this.counter}秒后重新获取`;
    this.counter--;
    setTimeout(this.countDown, 1000);
  };

  private getLoginInfo(): OrgUserLoginInfo | undefined {
    const mobile = this.mobileEl.value.trim();
    if (!mobile) {
      alert('请输入手机号码!');
      return;
    }
    const verifyCode = this.codeEl.value.trim();
    if (!verifyCode) {
      alert('请输入验证码!');
    }
    return {
      mobile,
      verifyCode,
      key: this.key,
      openId: this.openId,
      continueUrl: this.continueUrl,
    };
  }
}

export function salogin() {
  const manager = new SigninManager();
  manager.init();
}

/**
 * @file: MediatorDeviceOnboardStatusType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum MediatorDeviceOnboardStatusType {
  Inactive = 'inactive',
  Offline = 'offline',
  Online = 'online',
  Disabled = 'disabled'
}
/**
 * @file HttpError
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

/**
 * @class
 * @extends {Error}
 * The http error class w/ http related information.
 */
export class HttpError extends Error {
  code: string;
  subCode?: string;
  status: number;
  statusText: string;
  response: any;

  constructor(message: string | undefined) {
    super(message);
  }
}

/**
 * @file: InfiniteScroll.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { getViewportHeight } from 'utils/ViewportSize';

export interface InfiniteScrollOptions {
  onLoadMore: () => Promise<void>;
}

export class InfiniteScroll {
  private offsetHeight: number = 0;
  private viewportHeight: number = 0;
  private navHeaderHeight: number = 0;
  private isLoadingMore: boolean = false;
  private preventMultiScrollToBottom = false;

  constructor(private el: HTMLElement, private options: InfiniteScrollOptions) {
    this.adjustDocumentSize();
    el.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.adjustDocumentSize);
    window.addEventListener('orientationchange', this.adjustDocumentSize);
    document.body.addEventListener('touchmove', e => {
      e.preventDefault();
    }, { passive: false });
    this.el.addEventListener('touchmove', e => {
      e.stopPropagation();
      e.stopImmediatePropagation();
    });
    (window as any).__infiniteScroll__ = this;
  }

  invalidate() {
    this.adjustDocumentSize();
  }

  adjustDocumentSize = () => {
    const navHeader = document.querySelector('.header')! as HTMLElement;
    this.viewportHeight = getViewportHeight();
    this.navHeaderHeight = navHeader.offsetHeight;
    const offsetHeight = getViewportHeight() - this.navHeaderHeight;
    this.el.style.height = `${offsetHeight}px`;
    this.offsetHeight = this.el.offsetHeight;
    document.documentElement.style.height = `${this.viewportHeight}px`;
    document.documentElement.style.overflowY = 'hidden';
    document.body.style.height = `${this.viewportHeight}px`;
    document.body.style.overflowY = 'hidden';
  }

  onScroll = (e: Event) => {
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (this.el.scrollTop + this.offsetHeight >= this.el.scrollHeight) {
      if (this.preventMultiScrollToBottom) return;
      this.preventMultiScrollToBottom = true;
      setTimeout(() => {
        this.preventMultiScrollToBottom = false;
      }, 1000);
      if (this.isLoadingMore) return;
      this.options.onLoadMore().then(() => {
        this.isLoadingMore = false;
      }).catch(() => {
        this.isLoadingMore = false;
      });
    }
  }

  dispose() {
    this.el.addEventListener('scroll', this.onScroll);
  }
}
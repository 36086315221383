/**
 * @file: helper.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export function inputAllowNumbersOnly(el: HTMLInputElement) {
  el.addEventListener('keypress', e => {
    if (e.charCode < 48 || e.charCode > 57) {
      e.preventDefault();
      return;
    }
  });
}

export function withSelectEffect(el: HTMLElement, callback: () => void) {
  el.style.transition = 'all 0.1s cubic-bezier(0.22, 1, 0.36, 1)';
  el.style.backgroundColor = '#fafafa';

  setTimeout(() => {
    el.style.backgroundColor = '#fff';
    setTimeout(() => {
      (el.style as any).transition = null;
      callback();
    }, 100);
  }, 100);
}

export function addTransitionEndEvent(el: HTMLElement, listener: () => void) {
  let transition: string = '';
  if ('ontransitionend' in window) {
    transition = 'transitionend';
  } else if ('onwebkittransitionend' in window) {
    transition = 'webkitTransitionEnd';
  }
  let ended = false;
  const onTransitionEnd = () => {
    if (ended) return;
    ended = true;
    if (transition) el.removeEventListener(transition, onTransitionEnd);
    listener();
  };
  if (transition) {
    el.addEventListener(transition, onTransitionEnd);
  }
  // bookkeeping
  const durationStr = window.getComputedStyle(el).transitionDuration;
  const m = /^((?:(?:\d+)?\.)?\d+)(m?s)$/.exec(durationStr);
  let duration = 100;
  if (m) {
    if (m[1].charAt(0) === '.') {
      duration = Number('0' + m[1]);
    } else {
      duration = Number(m[1]);
    }
    if (m[2] === 's') {
      duration *= 1000;
    }
  }
  setTimeout(onTransitionEnd, duration);
}

export function escapeHtml(unsafe: string | number) {
  if (typeof unsafe === 'number') return String(unsafe);
  if (!unsafe) return '';
  return unsafe
       .replace(/&/g, "&amp;")
       .replace(/</g, "&lt;")
       .replace(/>/g, "&gt;")
       .replace(/"/g, "&quot;")
       .replace(/'/g, "&#039;");
}

export function addFormFieldsBehaviors(formEl: HTMLElement) {
  const textFields: HTMLInputElement[] = [
    ...[].slice.call(formEl.querySelectorAll('input[type="text"]')),
    ...[].slice.call(formEl.querySelectorAll('input[type="password"]')),
    ...[].slice.call(formEl.querySelectorAll('input[type="number"]')),
    ...[].slice.call(formEl.querySelectorAll('input[type="tel"]')),
    ...[].slice.call(formEl.querySelectorAll('input[type="email"]')),
  ];

  const addFormFieldClearableBehavior = (el: HTMLInputElement) => {
    const clearEl = document.createElement('a');
    clearEl.classList.add('form__field-clear');
    clearEl.style.display = 'none';
    clearEl.href = '#';

    const clearIcon = document.createElement('i');
    clearIcon.classList.add('icon-remove');
    clearEl.appendChild(clearIcon);

    el.insertAdjacentElement('afterend', clearEl);

    const onFormFieldChange = () => {
      if (el.value) {
        clearEl.style.display = 'flex';
      } else {
        clearEl.style.display = 'none';
      }
    };

    el.addEventListener('keyup', () => {
      onFormFieldChange();
    });

    el.addEventListener('change', () => {
      onFormFieldChange();
    });

    clearEl.addEventListener('click', (e) => {
      e.preventDefault();
      el.value = '';
      onFormFieldChange();
    });

    onFormFieldChange();
  };

  for (const formField of textFields) {
    addFormFieldClearableBehavior(formField);
  }
}

export function suspendIosFocusFix() {
  if (!iOS) return;
  (window as any).__fix_ios_scroll_issue__ = false;
}

export function resumeIosFocusFix(immediately = true) {
  if (!iOS) return;
  (window as any).__fix_ios_scroll_issue__ = true;
  const scrollTop = (window as any).__ios_scroll_top__;
  if (immediately) {
    window.scrollTo({ top: scrollTop, behavior: 'smooth' });
  }
}

export function withListItemClickable(listEl: HTMLElement | null | undefined, baseCls: string, accessorySelector?: string) {
  if (!listEl) return;

  accessorySelector = accessorySelector || `.${baseCls}__accessory-view > a`;

  listEl.addEventListener('click', (e: MouseEvent) => {
    let el = e.target as HTMLElement;
    while (el && !el.classList.contains(baseCls) && el !== listEl) {
      el = el.parentElement!;
    }
    if (!el || el === listEl) return;
    e.preventDefault();
    const linkEl = el.querySelector(accessorySelector!)! as HTMLAnchorElement;
    if (!linkEl) return;
    const url = linkEl.href;
    console.log(url);
    withSelectEffect(el, () => {
      location.href = url;
    });
  });
}

export function withPreviewImages(selector: string) {
  const urls: string[] = [];
  document.querySelectorAll(selector).forEach(el => {
    const url = el.getAttribute('data-url')!;
    urls.push(url);
    el.addEventListener('click', e => {
      e.preventDefault();
      wx.previewImage({
        current: url,
        urls
      });
    });
  });
}

export function withReportDetail() {
  const expandedCls = 'report-items--expanded';
  const groupExpandedCls = 'report-items__group--expanded';

  document.querySelectorAll('.report-items__header-toggle').forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault();
      const itemsEl = $(el).closest('.report-items')[0];
      if (itemsEl.classList.contains(expandedCls)) {
        itemsEl.classList.remove(expandedCls);
      } else {
        itemsEl.classList.add(expandedCls);
      }
    });
  });

  document.querySelectorAll('.report-items__group-header-toggle').forEach(el => {
    el.addEventListener('click', e => {
      e.preventDefault();
      const itemsEl = $(el).closest('.report-items__group')[0];
      if (itemsEl.classList.contains(groupExpandedCls)) {
        itemsEl.classList.remove(groupExpandedCls);
      } else {
        itemsEl.classList.add(groupExpandedCls);
      }
    });
  });
}
/**
 * @file: MobileSelect.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';
import { addTransitionEndEvent, escapeHtml } from '../helpers';

export type MobileSelectCallback = (indics: number[], data: any) => void;

export interface MobileSelectKeyMap {
  id: string;
  value: string;
  childs: string;
}

const classNames = {
  control: 'mobile-select',
  backdrop: 'mobile-select__gray-layer',
  content: 'mobile-select__content',
  btnBar: 'mobile-select__btn-bar',
  cancelBtn: 'mobile-select__cancel-btn',
  title: 'mobile-select__title',
  ensureBtn: 'mobile-select__ensure-btn',
  panel: 'mobile-select__panel',
  wheels: 'mobile-select__wheels',
  shadowMask: 'mobile-select__shadow-mask',
  selectLine: 'mobile-select__select-line',
  wheel: 'mobile-select__wheel',
  selectContainer: 'mobile-select__select-container',
  show: 'mobile-select--show',
  fixedWidth: 'mobile-select--fixed-width',
  item: 'mobile-select__item'
};

export interface MobileSelectConfig {
  trigger: string | HTMLElement;
  wheels: any[];
  callback?: MobileSelectCallback;
  transitionEnd?: MobileSelectCallback;
  cancel?: MobileSelectCallback;
  onShow?: (instance: MobileSelect) => void;
  onHide?: (instance: MobileSelect) => void;
  title?: string;
  position?: number[];
  connector?: string;
  ensureBtnText?: string;
  cancelBtnText?: string;
  ensureBtnColor?: string;
  cancelBtnColor?: string;
  titleColor?: string;
  titleBgColor?: string;
  textColor?: string;
  bgColor?: string;
  maskOpacity?: number;
  keyMap?: MobileSelectKeyMap;
  triggerDisplayData?: boolean;
}

type HtmlElementCollection = HTMLCollectionOf<HTMLElement>;

// helper functions
function queryElementsByCls(
  el: HTMLElement | HTMLDocument, cls: string
): HtmlElementCollection {
  return el.getElementsByClassName(cls) as any;
}

function int(value: any): number {
  if (typeof value === 'number') return Math.floor(value);
  if (typeof value === 'string') return parseInt(value, 10);
  return Math.floor(Number(value));
}

export class MobileSelect {
  private mobileSelect: HTMLElement;
  private wheelsData: any[];
  private jsonType: boolean = false;
  private cascadeJsonData: any[] = [];
  private displayJson: any[] = [];
  private curValue: any[] | null = null;
  private curIndexArr: number[] | null = null;
  private cascade: boolean = false;
  private startY: number = 0;
  private moveEndY: number = 0;
  private moveY: number = 0;
  private oldMoveY: number = 0;
  private offset: number = 0;
  private offsetSum: number = 0;
  private oversizeBorder: number = 0;
  private curDistance: number[] = [];
  private clickStatus: boolean = false;
  private isPC: boolean = true;
  private keyMap: MobileSelectKeyMap;

  // UI elements
  private trigger: HTMLElement;
  private title: HTMLElement;
  private content: HTMLElement;
  private wheel: HtmlElementCollection;
  private slider: HtmlElementCollection;
  private wheels: HTMLElement;
  private liHeight: number;
  private ensureBtn: HTMLElement;
  private cancelBtn: HTMLElement;
  private grayLayer: HTMLElement;
  private popUp: HTMLElement;
  private panel: HTMLElement;
  private btnBar: HTMLElement;
  private grayMask: HTMLElement;
  private shadowMask: HTMLElement;
  private initPosition: number[];
  private titleText: string;
  private connector: string;
  private triggerDisplayData: boolean;
  private initDeepCount: number = 0;
  private initPositionSet: boolean = false;

  constructor(private config: MobileSelectConfig) {
    this.wheelsData = this.config.wheels;
    this.init();
  }

  setTitle(title: string) {
    this.titleText = title;
    this.title.innerHTML = title;
  }

  getValue() {
    return this.curValue;
  }

  destroy() {
    this.mobileSelect.parentNode?.removeChild(this.mobileSelect);
  }

  show() {
    addTransitionEndEvent(this.mobileSelect, () => {
      this.config.onShow && this.config.onShow(this);
    });

    this.mobileSelect.style.display = 'block';
    this.liHeight = this.mobileSelect.querySelector('li')!.offsetHeight;
    if (!this.initPositionSet) {
      this.setCurDistance(this.initPosition);
      this.initPositionSet = true;
    }
    this.content.style.bottom = `-${this.content.offsetHeight}px`;

    setTimeout(() => {
      this.content.style.bottom = '0px';
      this.mobileSelect.classList.add(classNames.show);
    }, 0);
  }

  hide() {
    addTransitionEndEvent(this.mobileSelect, () => {
      this.mobileSelect.style.display = 'none';
      this.config.onHide && this.config.onHide(this);
    });
    this.mobileSelect.classList.remove(classNames.show);
    this.content.style.bottom = `-${this.content.offsetHeight}px`;
  }

  private init() {
    const { config } = this;

    this.keyMap = config.keyMap ? config.keyMap : { id:'id', value:'value', childs:'childs' };
    this.jsonType = typeof(this.wheelsData[0].data[0]) == 'object';

    this.renderWheels();

    const trigger = typeof config.trigger ===
      'string' ? document.querySelector(config.trigger) :
      config.trigger;

    if(!trigger){
      throw new Error(
        'mobileSelect has been successfully installed, but no trigger found on your page.'
        );
    }

    this.trigger = trigger as HTMLElement;

    this.wheel = queryElementsByCls(this.mobileSelect, classNames.wheel);
    this.slider = queryElementsByCls(this.mobileSelect, classNames.selectContainer);

    this.content = this.mobileSelect.querySelector(`.${classNames.content}`)! as HTMLElement;
    this.wheels = this.mobileSelect.querySelector(`.${classNames.wheels}`)! as HTMLElement;
    this.title = this.mobileSelect.querySelector(`.${classNames.title}`)! as HTMLElement;
    this.panel = this.mobileSelect.querySelector(`.${classNames.panel}`)! as HTMLElement;
    this.btnBar = this.mobileSelect.querySelector(`.${classNames.btnBar}`)! as HTMLElement;
    this.shadowMask = this.mobileSelect.querySelector(`.${classNames.shadowMask}`)! as HTMLElement;
    this.grayMask = this.mobileSelect.querySelector(`.${classNames.backdrop}`)! as HTMLElement;

    this.liHeight = this.mobileSelect.querySelector('li')!.offsetHeight;
    this.ensureBtn = this.mobileSelect.querySelector(`.${classNames.ensureBtn}`)! as HTMLElement;
    this.cancelBtn = this.mobileSelect.querySelector(`.${classNames.cancelBtn}`)! as HTMLElement;
    this.grayLayer = this.mobileSelect.querySelector(`.${classNames.backdrop}`)! as HTMLElement;
    this.popUp = this.mobileSelect.querySelector(`.${classNames.content}`)! as HTMLElement;

    this.initPosition = config.position || [];
    this.titleText = config.title || '';
    this.connector = config.connector || ' ';
    this.triggerDisplayData = config.triggerDisplayData !== false;
    this.trigger.style.cursor = 'pointer';
    this.isPC = /wechatdevtools/.test(navigator.userAgent) ||
      !/(android|ipad|iphone|micromessenger)/i.test(navigator.userAgent);

    this.setStyle();
    this.setTitle(this.titleText);

    this.checkCascade();
    this.initWheelEvents();

    if (this.cascade) {
      this.initCascade();
    }

    if(this.initPosition.length < this.slider.length){
      const diff = this.slider.length - this.initPosition.length;
      for(let i = 0; i < diff; i++){
        this.initPosition.push(0);
      }
    }

    this.setCurDistance(this.initPosition);

    this.initEvents();

    this.fixRowStyle(); //修正列数
  }

  private initCascade() {
    this.displayJson.push(this.generateArrData(this.cascadeJsonData));
    if (this.initPosition.length > 0) {
      this.initDeepCount = 0;
      this.initCheckArrDeep(this.cascadeJsonData[this.initPosition[0]]);
    } else {
      this.checkArrDeep(this.cascadeJsonData[0]);
    }
    this.reRenderWheels();
  }

  private initCheckArrDeep(parent: any) {
    if (!parent) return;
    const key = this.keyMap.childs;
    if (key && parent[key].length > 0) {
      this.displayJson.push(this.generateArrData(parent[key]));
      this.initDeepCount++;
      const nextNode = parent[key][this.initPosition[this.initDeepCount]];
      if (nextNode) {
        this.initCheckArrDeep(nextNode);
      } else {
        this.checkArrDeep(parent[key][0]);
      }
    }
  }

  private setStyle() {
    const { config } = this;
    const {
      ensureBtnColor,
      cancelBtnColor,
      titleColor,
      textColor,
      titleBgColor,
      bgColor,
      maskOpacity
    } = config;

    if (ensureBtnColor) {
      this.ensureBtn.style.color = ensureBtnColor;
    }
    if (cancelBtnColor) {
      this.cancelBtn.style.color = cancelBtnColor;
    }
    if (titleColor) {
      this.title.style.color = titleColor;
    }
    if (textColor) {
      this.panel.style.color = textColor;
    }
    if (titleBgColor) {
      this.btnBar.style.backgroundColor = titleBgColor;
    }
    if (bgColor) {
      this.panel.style.backgroundColor = bgColor;
      const backgroundGradientColor = `linear-gradient(to bottom, ${bgColor}, rgba(255, 255, 255, 0), ${bgColor})`;
      this.shadowMask.style.background = backgroundGradientColor;
    }

    if (typeof maskOpacity === 'number') {
      this.grayMask.style.background = `rgba(0, 0, 0, ${maskOpacity}')`;
    }
  }

  private checkCascade() {
    if (!this.jsonType) {
      this.cascade = false;
      return;
    }
    const node = this.wheelsData[0].data;
    for (let i = 0; i < node.length; i++) {
      const key = this.keyMap.childs;
      if (key in node[i] && node[i][key].length > 0) {
        this.cascade = true;
        this.cascadeJsonData = this.wheelsData[0].data;
        break;
      }
    }
  }

  private initEvents() {
    this.cancelBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.hide();
    });

    this.ensureBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.hide();
      if(!this.liHeight) {
        this.liHeight =  this.mobileSelect.querySelector('li')!.offsetHeight;
      }

      let buffer: string[] = [];
      for(let i = 0; i < this.wheel.length; i++) {
        buffer.push(this.getInnerHtml(i));
      }
      if(this.triggerDisplayData){
        this.trigger.innerHTML = buffer.join(this.connector);
      }
      this.curIndexArr = this.getIndexArr();
      this.curValue = this.getCurValue();
      this.config.callback && this.config.callback(
        this.curIndexArr, this.curValue
        );
    });

    this.trigger.addEventListener('click', (e) => {
      e.preventDefault();
      this.show();
    });

    this.grayLayer.addEventListener('click', (e) => {
      e.preventDefault();
      this.hide();
    });

    this.popUp.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }

  private initWheelEvents() {
    for (let i = 0; i < this.slider.length; i++) {
      this.addWheelEventListeners(this.wheel[i], i);
    }
  }

  private addWheelEventListeners(wheel: HTMLElement, index: number) {
    wheel.addEventListener('touchstart', (event: TouchEvent) => {
      this.onTouchEvent(event, wheel.firstChild! as HTMLElement, index);
    }, false);
    wheel.addEventListener('touchend', (event: TouchEvent) => {
      this.onTouchEvent(event, wheel.firstChild! as HTMLElement, index);
    }, false);
    wheel.addEventListener('touchmove', (event: TouchEvent) => {
      this.onTouchEvent(event, wheel.firstChild! as HTMLElement, index);
    }, false);

    if (this.isPC) {
      wheel.addEventListener('mousedown', (event: MouseEvent) => {
        this.onMouseEvent(event, wheel.firstChild! as HTMLElement, index);
      }, false);
      wheel.addEventListener('mousemove', (event: MouseEvent) => {
        this.onMouseEvent(event, wheel.firstChild! as HTMLElement, index);
      }, false);
      wheel.addEventListener('mouseup', (event: MouseEvent) => {
        this.onMouseEvent(event, wheel.firstChild! as HTMLElement, index);
      }, true);
    }
  }

  private onTouchEvent = (event: TouchEvent, slider: HTMLElement, index: number) => {
    switch (event.type) {
      case 'touchstart': {
        this.startY = int(event.touches[0].clientY);
        this.oldMoveY = this.startY;
        break;
      }

      case 'touchend':
        this.moveEndY = int(event.changedTouches[0].clientY);
        this.offsetSum = this.moveEndY - this.startY;
        this.oversizeBorder = -(slider.getElementsByTagName('li').length - 3) * this.liHeight;

        if (this.offsetSum === 0) {
          const viewportHeight = document.documentElement.clientHeight;
          const clickOffetNum = int((viewportHeight - this.moveEndY) / 40);
          if (clickOffetNum !== 2) {
            const offset = clickOffetNum - 2;
            const newDistance = this.curDistance[index] + (offset * this.liHeight);
            if ((newDistance <= 2 * this.liHeight) && (newDistance >= this.oversizeBorder)) {
              this.curDistance[index] = newDistance;
              this.movePosition(slider, this.curDistance[index]);
              this.config.transitionEnd && this.config.transitionEnd(
                this.getIndexArr(), this.getCurValue()
                );
            }
          }
        } else {
          this.updateCurDistance(slider, index);
          this.curDistance[index] = this.fixPosition(this.curDistance[index]);
          this.movePosition(slider, this.curDistance[index]);

          if (this.curDistance[index] + this.offsetSum > 2 * this.liHeight) {
            this.curDistance[index] = 2 * this.liHeight;
            setTimeout(() => {
              this.movePosition(slider, this.curDistance[index]);
            }, 100);

          } else if (this.curDistance[index] + this.offsetSum < this.oversizeBorder) {
            this.curDistance[index] = this.oversizeBorder;
            setTimeout(() => {
              this.movePosition(slider, this.curDistance[index]);
            }, 100);
          }
          this.config.transitionEnd && this.config.transitionEnd(
            this.getIndexArr(), this.getCurValue()
            );
        }

        if (this.cascade) {
          this.checkRange(index, this.getIndexArr());
        }

        break;

      case 'touchmove':
        event.preventDefault();
        this.moveY = event.touches[0].clientY;
        this.offset = this.moveY - this.oldMoveY;

        this.updateCurDistance(slider, index);
        this.curDistance[index] = this.curDistance[index] + this.offset;
        this.movePosition(slider, this.curDistance[index]);
        this.oldMoveY = this.moveY;
        break;
    }
  }

  private movePosition(slider: HTMLElement, distance: number) {
    slider.style.webkitTransform = 'translate3d(0,' + distance + 'px, 0)';
    slider.style.transform = 'translate3d(0,' + distance + 'px, 0)';
  }

  private updateCurDistance(slider: HTMLElement, index: number) {
    const transform = slider.style.transform || slider.style.webkitTransform;
    this.curDistance[index] = parseInt(transform.split(',')[1], 10);
  }

  private fixPosition(distance: number) {
    return -(this.getIndex(distance) - 2) * this.liHeight;
  }

  private checkRange(index: number, posIndexArr: number[]) {
    var deleteNum = this.displayJson.length - 1 - index;
    for (var i = 0; i < deleteNum; i++) {
      this.displayJson.pop();
    }
    let resultNode: any;
    for (var i = 0; i <= index; i++) {
      if (i == 0)
        resultNode = this.cascadeJsonData[posIndexArr[0]];
      else {
        resultNode = resultNode[this.keyMap.childs][posIndexArr[i]];
      }
    }

    this.checkArrDeep(resultNode);
    this.reRenderWheels();
    this.fixRowStyle();
    this.setCurDistance(this.resetPosition(index, posIndexArr));
  }

  private checkArrDeep(parent: any) {
    if (!parent) return;
    const key = this.keyMap.childs;
    if (key in parent && parent[key].length > 0) {
      this.displayJson.push(this.generateArrData(parent[key]));
      this.checkArrDeep(parent[key][0]);
    }
  }

  private generateArrData(targetArr: any[]) {
    const { id: idProp, value: textProp } = this.keyMap;
    return targetArr.map(x => ({
      [idProp]: x[idProp],
      [textProp]: x[textProp]
    }));
  }

  private calcDistance(index: number) {
    return 2 * this.liHeight - index * this.liHeight;
  }

  private setCurDistance(indexArr: number[]) {
    const temp: number[] = [];
    for (var i = 0; i < this.slider.length; i++) {
      temp.push(this.calcDistance(indexArr[i]));
      this.movePosition(this.slider[i], temp[i]);
    }
    this.curDistance = temp;
  }

  private resetPosition(index: number, posIndexArr: number[]) {
    const tempPosArr = posIndexArr;
    let tempCount = 0;
    if (this.slider.length > posIndexArr.length) {
      tempCount = this.slider.length - posIndexArr.length;
      for (let i = 0; i < tempCount; i++) {
        tempPosArr.push(0);
      }
    } else if (this.slider.length < posIndexArr.length) {
      tempCount = posIndexArr.length - this.slider.length;
      for (let i = 0; i < tempCount; i++) {
        tempPosArr.pop();
      }
    }
    for (let i = index + 1; i < tempPosArr.length; i++) {
      tempPosArr[i] = 0;
    }
    return tempPosArr;
  }

  private renderWheels() {
    const { config, wheelsData } = this;

    var cancelText = config.cancelBtnText || '取消';
    var ensureText = config.ensureBtnText || '确认';

    this.mobileSelect = document.createElement('div');
    this.mobileSelect.className = classNames.control;
    this.mobileSelect.innerHTML =[
      `<div class="${classNames.backdrop}"></div>`,
      `<div class="${classNames.content}">`,
      `  <div class="${classNames.btnBar}">`,
      `    <div class="${classNames.fixedWidth}">`,
      `      <div class="${classNames.cancelBtn}">${escapeHtml(cancelText)}</div>`,
      `      <div class="${classNames.title}"></div>`,
      `      <div class="${classNames.ensureBtn}">${escapeHtml(ensureText)}</div>`,
      '    </div>',
      '  </div>',
      `  <div class="${classNames.panel}">`,
      `    <div class="${classNames.fixedWidth}">`,
      `      <div class="${classNames.wheels}"></div>`,
      `      <div class="${classNames.selectLine}"></div>`,
      `      <div class="${classNames.shadowMask}"></div>`,
      '    </div>',
      '  </div>',
      '</div>'
    ].join('');


    document.body.appendChild(this.mobileSelect);

    let buffer: string[] = [];
    for (let i = 0; i < wheelsData.length; i++) {
      buffer.push(
        `<div class="${classNames.wheel}">`,
        `<ul class="${classNames.selectContainer}">`
        );
      if (this.jsonType) {
        for (var j = 0; j < wheelsData[i].data.length; j++) {
          const data = wheelsData[i].data[j];
          const value = escapeHtml(data[this.keyMap.id]);
          const text = escapeHtml(data[this.keyMap.value]);
          buffer.push(`<li class="${classNames.item}" data-id="${value}">${text}</li>`);
        }
      } else {
        for (let j = 0; j < wheelsData[i].data.length; j++) {
          const text = escapeHtml(wheelsData[i].data[j]);
          buffer.push(`<li class="${classNames.item}">${text}</li>`);
        }
      }
      buffer.push('</ul></div>');
    }

    this.mobileSelect
      .querySelector(`.${classNames.wheels}`)!
      .innerHTML = buffer.join('');
  }

  private reRenderWheels() {
    if (this.wheel.length > this.displayJson.length) {
      const count = this.wheel.length - this.displayJson.length;
      for (let i = 0; i < count; i++) {
        this.wheels.removeChild(this.wheel[this.wheel.length - 1]);
      }
    }

    const renderWheelItems = (buffer: string[], i: number) => {
      for (let j = 0; j < this.displayJson[i].length; j++) {
        const data = this.displayJson[i][j];
        const value = escapeHtml(data[this.keyMap.id]);
        const text = escapeHtml(data[this.keyMap.value]);
        buffer.push(`<li class="${classNames.item}" data-id="${value}">${text}</li>`);
      }
    };

    for (let i = 0; i < this.displayJson.length; i++) {
      const buffer: string[] = [];
      if (this.wheel[i]) {
        renderWheelItems(buffer, i);
        this.slider[i].innerHTML = buffer.join('');
      } else {
        const tempWheel = document.createElement('div');
        tempWheel.className = classNames.wheel;
        const buffer: string[] = [];
        buffer.push(`<ul class="${classNames.selectContainer}">`);
        renderWheelItems(buffer, i);
        buffer.push('</ul>');
        tempWheel.innerHTML = buffer.join('');

        this.addWheelEventListeners(tempWheel, i);
        this.wheels.appendChild(tempWheel);
      }
    }
  }

  private fixRowStyle() {
    const width = (100 / this.wheel.length).toFixed(2);
    for (var i = 0; i < this.wheel.length; i++) {
      this.wheel[i].style.width = width + '%';
    }
  }

  private getIndex(distance: number) {
    return Math.round((2 * this.liHeight - distance) / this.liHeight);
  }

  private getIndexArr() {
    return this.curDistance.map(x => this.getIndex(x));
  }

  private getInnerHtml(sliderIndex: number) {
    const index = this.getIndex(this.curDistance[sliderIndex]);
    return this.slider[sliderIndex].getElementsByTagName('li')[index].innerHTML;
  }

  private getCurValue() {
    var indics = this.getIndexArr();

    if (this.cascade) {
      return this.displayJson.map((x, i) => x[indics[i]]);
    }

    if (this.jsonType) {
      const { wheelsData } = this;
      return this.curDistance.map((x, i) => wheelsData[i].data[this.getIndex(x)]);
    }

    return this.curDistance.map((_, i) => this.getInnerHtml(i));
  }

  private onMouseEvent(event: MouseEvent, theSlider: HTMLElement, index: number) {
    switch (event.type) {
      case 'mousedown':
        this.startY = event.clientY;
        this.oldMoveY = this.startY;
        this.clickStatus = true;
        break;

      case 'mouseup':
        this.moveEndY = event.clientY;
        this.offsetSum = this.moveEndY - this.startY;
        this.oversizeBorder = -(theSlider.getElementsByTagName('li').length - 3) * this.liHeight;

        if (this.offsetSum == 0) {
          const clientHeight = document.documentElement.clientHeight;
          const clickOffetNum = int((clientHeight - this.moveEndY) / 40);
          if (clickOffetNum !== 2) {
            const offset = clickOffetNum - 2;
            const newDistance = this.curDistance[index] + (offset * this.liHeight);
            if ((newDistance <= 2 * this.liHeight) && (newDistance >= this.oversizeBorder)) {
              this.curDistance[index] = newDistance;
              this.movePosition(theSlider, this.curDistance[index]);
              this.config.transitionEnd && this.config.transitionEnd(
                this.getIndexArr(), this.getCurValue()
                );
            }
          }
        } else {
          this.updateCurDistance(theSlider, index);
          this.curDistance[index] = this.fixPosition(this.curDistance[index]);
          this.movePosition(theSlider, this.curDistance[index]);

          if (this.curDistance[index] + this.offsetSum > 2 * this.liHeight) {
            this.curDistance[index] = 2 * this.liHeight;
            setTimeout(() => {
              this.movePosition(theSlider, this.curDistance[index]);
            }, 100);

          } else if (this.curDistance[index] + this.offsetSum < this.oversizeBorder) {
            this.curDistance[index] = this.oversizeBorder;
            setTimeout(() => {
              this.movePosition(theSlider, this.curDistance[index]);
            }, 100);
          }
          this.config.transitionEnd && this.config.transitionEnd(
            this.getIndexArr(), this.getCurValue()
            );
        }

        this.clickStatus = false;
        if (this.cascade) {
          this.checkRange(index, this.getIndexArr());
        }
        break;

      case 'mousemove':
        event.preventDefault();
        if (this.clickStatus) {
          this.moveY = event.clientY;
          this.offset = this.moveY - this.oldMoveY;
          this.updateCurDistance(theSlider, index);
          this.curDistance[index] = this.curDistance[index] + this.offset;
          this.movePosition(theSlider, this.curDistance[index]);
          this.oldMoveY = this.moveY;
        }
        break;
    }
  }
}
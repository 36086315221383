/**
 * @file: AdminUserRole.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export const enum AdminUserRole {
  SuperUsers = 'superusers',
  Administrators = 'administrators',
  CustomerSupport = 'customersupport',
  Sales = 'sales'
}
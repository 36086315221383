export function getViewportWidth(): number {
  return getSize('width');
}

export function getViewportHeight(): number {
  return getSize('height');
}

function getPropName(prefix: string, name: string): string {
  return `${prefix}${name.charAt(0).toUpperCase() + name.substr(1)}`;
}

function getSize(name: string): number {
  name = name.toLowerCase();

  let size: number;

  const innerPropName = getPropName('inner', name);
  const clientPropName = getPropName('client', name);
  const offsetPropName = getPropName('offset', name);

  const document = window.document as any;
  const documentElement = document.documentElement as any;

  const win: any = window;

  if (win[innerPropName] === void 0) {
    // IE6 & IE7 don't have window.innerWidth or innerHeight
    size = documentElement[clientPropName];
  } else if (win[innerPropName] !== documentElement[clientPropName]) {
    // WebKit doesn't include scrollbars while calculating
    // viewport size so we have to get fancy
    // Insert markup to test if a media query will match
    // document.doumentElement["client" + Name]
    const bodyElement = document.createElement('body');
    bodyElement.id = 'vpw-test-b';
    bodyElement.style.cssText = 'overflow:scroll';
    const divElement = document.createElement('div');
    divElement.id = 'vpw-test-d';
    divElement.style.cssText = 'position:absolute;top:-1000px';

    // Getting specific on the CSS selector so it won't get overridden easily
    divElement.innerHTML = [
      '<style>@media(',
      documentElement[clientPropName],
      'px){body#vpw-test-b div#vpw-test-d{',
      name,
      ':7px!important}}</style>'
    ].join('');
    bodyElement.appendChild(divElement);
    documentElement.insertBefore(bodyElement, document.head);

    if (divElement[offsetPropName] === 7) {
      // Media query matches document.documentElement["client" + Name]
      size = documentElement[clientPropName];
    } else {
      // Media query didn't match, use window["inner" + Name]
      size = win[innerPropName];
    }
    // Cleanup
    documentElement.removeChild(bodyElement);
  } else {
    // Default to use window["inner" + Name]
    size = win[innerPropName];
  }
  return size;
}
/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export * from './VehicleReceptionStats';
export * from './StoreSummaryStats';
export * from './AreaNode';
export * from './AreaTree';
export * from './Option';
export * from './OrgUserSignupInfo';
export * from './OrgUserLoginInfo';
export * from './OcrImageInfo';
export * from './LicensePlateNoRecognizeInfo';
export * from './PlaceOrderResult';
export * from './UserLoginInfo';
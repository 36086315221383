/**
 * @file: InspectionTaskJobMediaCategory.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum InspectionTaskJobMediaCategory {
  Comparison = 1,
  Procedure
}

export const InspectionTaskJobMediaCategoryValueSet = new Set([
  InspectionTaskJobMediaCategory.Comparison,
  InspectionTaskJobMediaCategory.Procedure
]);
import { InfiniteScroll } from './InfiniteScroll';
import { withListItemClickable } from './helpers';
import { toast } from './toast';
import { spin, unspin } from 'shared';

export abstract class AbstractListManager<TFilter = any> {
  protected listEl: HTMLElement;
  protected infiniteScroll: InfiniteScroll;
  protected limit: number;
  protected offset: number = 0;
  protected count: number = 0;
  protected hasMore: boolean = false;
  protected itemSelector: string;
  protected filter: any = {};

  constructor(private itemCls: string, private name: string, private defaultLimit?: number) {
    this.itemSelector = `.${this.itemCls}`;
  }

  init() {
    this.listEl = document.querySelector('.infinite-scroll-list')! as HTMLElement;
    if (this.listEl) {
      this.limit = Number(this.listEl.getAttribute('data-limit')) || this.defaultLimit || 5;
      this.filter = JSON.parse(this.listEl.getAttribute('data-filter') || '{}');
      this.count = this.listEl.querySelectorAll(this.itemSelector).length;
      this.hasMore = this.count >= this.limit;
      this.infiniteScroll = new InfiniteScroll(this.listEl, {
        onLoadMore: this.onLoadMore
      });
      withListItemClickable(this.listEl, this.itemCls);
      this.onInit(this.listEl);
    }
  }

  dispose() {
    this.infiniteScroll.dispose();
  }

  abstract fetch(offset: number, limit: number, filter: TFilter): Promise<string>;

  // @ts-ignore
  onInit(listEl: HTMLElement) {}

  private onLoadMore = async () => {
    if (!this.hasMore) {
      toast('没有更多了');
      return;
    }

    spin();

    try {
      const result = await this.fetch(
        this.offset + this.limit, this.limit, this.filter
        );

      unspin();

      if (result) {
        this.listEl.insertAdjacentHTML('beforeend', result);
      }

      const count = this.listEl.querySelectorAll(this.itemSelector).length;
      const fetchedCount = count - this.count;

      if (fetchedCount < this.limit) {
        this.hasMore = false;
      }

      this.count = count;
      this.offset += fetchedCount;

      if (!fetchedCount) {
        toast('没有更多了');
      }
    } catch (e) {
      unspin();
      toast(`获取${this.name}列表失败`);
    }
  }
}
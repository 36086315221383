/**
 * @file: ApprovalAction.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum ApprovalAction {
  Accept = 'accept',
  Reject = 'reject'
}
/**
 * @file: UserRoleType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export const enum UserRoleType {
  GeneralUser = 'general',
  GuestAgent = 'guest_agent'
}
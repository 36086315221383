/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';
import qs from 'qs';
import { snakeCase } from 'change-case';
import { withSelectEffect, toast, ActionSheet, addFormFieldsBehaviors } from 'lib/ui';
import { InfiniteScroll } from 'lib/ui/InfiniteScroll';
import { serviceAgentService } from 'services';
import { spin, unspin } from 'shared';
import { InspectionOrderPriority, InspectionOrderType } from 'model';

const Priorities = [
  { value: InspectionOrderPriority.Normal, text: '普通优先级' },
  { value: InspectionOrderPriority.High, text: '高优先级' },
  { value: InspectionOrderPriority.Urgent, text: '紧急优先级' }
];

const OrderTypes = [
  { value: InspectionOrderType.Basic, text: '基础保养服务' },
  { value: InspectionOrderType.Full, text: '全面保养服务' },
  { value: InspectionOrderType.Repair, text: '维修服务' }
];

interface TaskQueryFilter {
  status: 'pending' | 'inprogress' | 'finished' | 'cancelled';
  scope: 'store' | 'self';
  orderType: InspectionOrderType;
  licensePlateNo: string;
  brand: string;
  customerName: string;
  taskNo: string;
  saName: string;
  priority: InspectionOrderPriority;
}

class SaDashboardManager {
  private tasksEl: HTMLElement;
  private taskListEl: HTMLElement | null;
  // @ts-ignore
  private infiniteScroll: InfiniteScroll;
  private limit: number;
  private offset: number = 0;
  private count: number = 0;
  private hasMore: boolean = false;
  private searchEl: HTMLElement;
  private filter: Partial<TaskQueryFilter> = {};
  private searchActionSheet: ActionSheet | null;

  init() {
    this.tasksEl = document.querySelector('.satasks')! as HTMLElement;
    this.searchEl = document.querySelector('.header__search')! as HTMLElement;
    this.taskListEl = document.querySelector('.infinite-scroll-list');
    this.filter = JSON.parse(this.tasksEl.getAttribute('data-filter')!);

    if (this.taskListEl) {
      this.limit = Number(this.taskListEl.getAttribute('data-limit'));
      this.count = this.taskListEl.querySelectorAll('.satask').length;
      this.hasMore = this.count >= this.limit;
      this.infiniteScroll = new InfiniteScroll(this.taskListEl, {
        onLoadMore: this.onLoadMore
      });
    }
    this.initEvents();
  }

  initEvents() {
    this.taskListEl?.addEventListener('click', this.onListClick);
    this.searchEl.addEventListener('click', this.onSearchButtonClick);
    const buttons = [].slice.call(document.querySelectorAll('.satasks__status-filter .btn'));
    for (const btn of buttons) {
      const inputEl = btn.querySelector('input')! as HTMLInputElement;
      const url = inputEl.getAttribute('data-url')!;
      btn.addEventListener('click', () => {
        setTimeout(() => {
          location.href = url;
        }, 200);
      });
    }
  }

  onSearchButtonClick = (e: Event) => {
    e.preventDefault();
    this.searchActionSheet = new ActionSheet({
      noPadding: true,
      contents: this.renderSearchActionSheetContents(),
      titleBar: {
        cancelButtonText: '返回',
        commitButtonText: '搜索',
        title: '搜索条件'
      },
      onRender: (containerEl: HTMLElement) => {
        const getField = <T extends HTMLElement = HTMLInputElement>(name: string): T => {
          return containerEl.querySelector(`[name="${name}"]`)! as T;
        };
        getField('order-type').value = this.filter.orderType || '';
        getField('license-plate-no').value = this.filter.licensePlateNo || '';
        getField('brand').value = this.filter.brand || '';
        getField('customer-name').value = this.filter.customerName || '';
        getField('task-no').value = this.filter.taskNo || '';
        getField('sa-name').value = this.filter.saName || '';
        getField<HTMLSelectElement>('priority').value = `${this.filter.priority || ''}`;

        addFormFieldsBehaviors(containerEl);
      },
      onDismissed: () => {
        this.searchActionSheet = null;
      },
      onCommit: (containerEl: HTMLElement) => {
        const getFieldValue = <T extends HTMLInputElement & HTMLSelectElement = any>(name: string): string | undefined => {
          return (containerEl.querySelector(`[name="${name}"]`)! as T).value || undefined;
        };
        this.filter.licensePlateNo = getFieldValue('license-plate-no');
        this.filter.brand = getFieldValue('brand');
        this.filter.customerName = getFieldValue('customer-name');
        this.filter.taskNo = getFieldValue('task-no');
        this.filter.saName = getFieldValue('sa-name');
        this.filter.priority = Number(getFieldValue('priority')) || undefined;
        this.filter.orderType = getFieldValue('order-type') as InspectionOrderType || undefined;

        this.searchActionSheet?.dismiss(() => {
          const query = qs.parse(location.search.substr(1));
          for (const name of Object.keys(this.filter)) {
            query[snakeCase(name)] = this.filter[name];
          }
          const queryString = qs.stringify(query);
          const url = [location.pathname, queryString].filter(x => x).join('?');
          location.href = url;
        });
      },
      onCancel: () => {
        this.searchActionSheet?.dismiss();
      }
    });
    this.searchActionSheet.present();
  }

  renderSearchActionSheetContents() {
    return [
      '<form class="form sa-search-form" onsubmit="return false">',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">服务类型: </label>',
      '    <select class="form__field" name="order-type">',
      '      <option value="">全部服务类型</option>',
      ...OrderTypes.map(x => `<option value="${x.value}">${x.text}</option>`),
      '    </select>',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">车牌号: </label>',
      '    <input type="text" class="form__field" name="license-plate-no" placeholder="按车牌号搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">车型/品牌: </label>',
      '    <input type="text" class="form__field" name="brand" placeholder="按车型或品牌搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">客户姓名: </label>',
      '    <input type="text" class="form__field" name="customer-name" placeholder="按客户姓名搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">任务编号: </label>',
      '    <input type="text" class="form__field" name="task-no" placeholder="按任务编号搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">服务顾问: </label>',
      '    <input type="text" class="form__field" name="sa-name" placeholder="按服务顾问名字搜索" />',
      '  </div>',
      '  <div class="form__field-ct">',
      '    <label class="form__field-label">优先级: </label>',
      '    <select class="form__field" name="priority">',
      '      <option value="">全部</option>',
      ...Priorities.map(x => `<option value="${x.value}">${x.text}</option>`),
      '    </select>',
      '  </div>',
      '</form>'
    ].join('');
  }

  onListClick = (e: Event) => {
    // find the satask element
    let el = e.target as HTMLElement;
    while (el && !el.classList.contains('satask') && el !== this.taskListEl) {
      el = el.parentElement!;
    }
    if (!el || el === this.taskListEl) return;
    e.preventDefault();
    const linkEl = el.querySelector('.satask__accessory-view > a')! as HTMLAnchorElement;
    const url = linkEl.href;
    console.log(url);
    withSelectEffect(el, () => {
      location.href = url;
    });
  }

  onLoadMore = async () => {
    if (!this.hasMore) {
      toast('没有更多了');
      return;
    }
    spin();
    try {
      const result = await serviceAgentService.renderTaskList(
        this.offset + this.limit, this.limit, this.filter
        );
      unspin();
      this.taskListEl!.insertAdjacentHTML('beforeend', result);
      const count = this.taskListEl!.querySelectorAll('.satask').length;
      const fetchedCount = count - this.count;
      if (fetchedCount < this.limit) {
        this.hasMore = false;
      }
      this.count = count;
      this.offset += fetchedCount;
      if (!fetchedCount) {
        toast('没有更多了');
      }
    } catch (e) {
      unspin();
      toast('获取工单列表失败');
    }
  }
}

export const saDashboardManager = new SaDashboardManager();

export function sadashboard() {
  saDashboardManager.init();
}
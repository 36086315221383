/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';
import qs from 'qs';
import { isMobile } from 'utils/validators';
import { spin, unspin } from 'shared';
import { smsService, userService } from 'services';
import { inputAllowNumbersOnly } from 'lib/ui/helpers';
import { UserLoginInfo } from 'model';
import { HttpError } from 'lib/restful-client/HttpError';

class UserLoginManager {
  private mobileEl: HTMLInputElement;
  private codeEl: HTMLInputElement;
  private sendButton: HTMLButtonElement;
  private submitButton: HTMLButtonElement;
  private key: string;
  private scene: string;
  private openId: string;
  private continueUrl: string = '';
  private counter: number = 0;
  private originalSendBtnText: string;

  init() {
    this.mobileEl = document.getElementById('mobile')! as HTMLInputElement;
    this.codeEl = document.getElementById('code')! as HTMLInputElement;
    this.sendButton = document.getElementById('sendCode')! as HTMLButtonElement;
    this.submitButton = document.getElementById('btn-submit')! as HTMLButtonElement;
    this.originalSendBtnText = this.sendButton.innerText;

    this.key = (document.getElementById('key')! as HTMLInputElement).value;
    this.scene = (document.getElementById('scene')! as HTMLInputElement).value;
    this.openId = (document.getElementById('openid')! as HTMLInputElement).value;

    const query = qs.parse(location.search.substr(1));
    this.continueUrl = query.continue_url || '/index.html';

    this.initEvents();
  }

  initEvents() {
    inputAllowNumbersOnly(this.mobileEl);
    inputAllowNumbersOnly(this.codeEl);

    this.mobileEl.addEventListener('keyup', e => {
      const el = e.target as HTMLInputElement;
      this.sendButton.disabled = !isMobile(el.value);
    });

    this.mobileEl.addEventListener('change', e => {
      const el = e.target as HTMLInputElement;
      this.sendButton.disabled = !isMobile(el.value);
    });

    this.sendButton.addEventListener('click', this.onSendButtonClick);

    this.submitButton.addEventListener('click', this.onLogin);

    const altenativeLoginSwitch = document
      .querySelector('.login__alternative-switch');
    if (altenativeLoginSwitch) {
      altenativeLoginSwitch.addEventListener(
        'click', this.onSwitchLoginAlternative
        );
    }
  }

  getLoginAlternativeEl(loginType: string) {
    const selector = `.login__alternative--${loginType}`;
    return document.querySelector(selector) as HTMLElement;
  }

  getLoginTypeEl() {
    return document.querySelector(
      'input[name="login_type"]'
      ) as HTMLInputElement;
  }

  getLoginType() {
    return this.getLoginTypeEl().value;
  }

  onSwitchLoginAlternative = (e: MouseEvent) => {
    e.preventDefault();
    const loginTypeEl = this.getLoginTypeEl();
    const titleEl = document.querySelector(
      '#login__sub-title'
      ) as HTMLElement;
    const currentLoginType = loginTypeEl.value;
    const switchEl = (e.currentTarget as HTMLElement);
    const loginType = switchEl.getAttribute('data-login-type')!;
    const currentEl = this.getLoginAlternativeEl(currentLoginType)!;
    const el = this.getLoginAlternativeEl(loginType);
    if (el) {
      currentEl.style.display = 'none';
      el.style.display = '';
      switchEl.setAttribute('data-login-type', currentLoginType);
      loginTypeEl.value = loginType;
      titleEl.innerText = el.getAttribute('data-title')!;
      switchEl.innerText = currentEl.getAttribute('data-switch')!;
    }
  }

  onLogin = async (e: Event) => {
    e.preventDefault();

    const loginInfo = this.getLoginInfo();
    if (!loginInfo) return;

    try {
      spin();
      const redirectUrl = await userService.login(loginInfo);
      unspin();
      location.href = redirectUrl;
    } catch (e) {
      unspin();
      this.handleLoginError(e);
    }
  }

  handleLoginError(error: HttpError) {
    console.error(error);

    if (error.subCode === 'weixin_reauth_required') {
      location.href = this.continueUrl;
      return;
    }

    let errMsg: string = '';

    switch (error.subCode) {
      case 'missing_verify_code':
        errMsg = '请输入手机验证码!';
        break;
      case 'verify_code_expired':
        errMsg = '验证码已过期，请重新获取验证码!';
        break;
      case 'invalid_verify_code':
        errMsg = '验证码不正确!';
        break;
      case 'missing_license_plate_no':
        errMsg = '请输入车牌号码!';
        break;
      case 'invalid_license_plate_no':
        errMsg = '车牌格式有误!';
        break;
      case 'license_plate_no_verify_failed':
        errMsg = '车牌验证失败!';
        break;
      default:
        errMsg = '对不起，登录失败，请稍候重试!';
        break;
    }

    alert(errMsg);
  }

  onSendButtonClick = async () => {
    spin();
    this.sendButton.disabled = true;
    try {
      this.counter = await smsService.sendVerifyCode(this.mobileEl.value, this.key, this.scene);
      unspin();
      this.countDown();
    } catch (e) {
      console.error(e);
      this.sendButton.disabled = false;
      alert('发送验证失败，请稍后重试!');
      unspin();
    }
  }

  countDown = () => {
    if (!this.counter) {
      this.sendButton.disabled = false;
      this.sendButton.innerText = this.originalSendBtnText;
      return;
    }
    this.sendButton.innerText = `${this.counter}秒后重新获取`;
    this.counter--;
    setTimeout(this.countDown, 1000);
  }

  private getLoginInfo(): UserLoginInfo | undefined {
    const loginInfo: UserLoginInfo = {
      loginType: this.getLoginType(),
      openId: this.openId,
      continueUrl: this.continueUrl,
      key: this.key,
      mobile: '',
      verifyCode: '',
      reportNo: '',
      licensePlateNo: ''
    };
    if (loginInfo.loginType === 'mobile') {
      const mobile = this.mobileEl.value.trim();
      if (!mobile) {
        alert('请输入手机号码!');
        return;
      }
      const verifyCode = this.codeEl.value.trim();
      if (!verifyCode) {
        alert('请输入验证码!');
      }
      loginInfo.mobile = mobile;
      loginInfo.verifyCode = verifyCode;
    } else if (loginInfo.loginType === 'license-plate-no-verify') {
      const licensePlateNo = (
        document.querySelector(
          '#license_plate_no'
        ) as HTMLInputElement
      ).value.trim();
      if (!licensePlateNo) {
        alert('请输入车牌号码!');
        return;
      }
      if (licensePlateNo.length < 7) {
        alert('车牌号码格式不正确!');
        return;
      }
      const reportNo = (
        document.querySelector(
          'input[name="reportno"]'
        ) as HTMLInputElement
      ).value;
      loginInfo.reportNo = reportNo;
      loginInfo.licensePlateNo = licensePlateNo.toUpperCase();
    }

    return loginInfo;
  }
}

export function login() {
  const manager = new UserLoginManager();
  manager.init();
}
/**
 * @file: InspectionSiteRelType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum InspectionSiteRelType {
  Default = 1,
  Structural = 2,
  Spatial = 3,
  Workflow = 4
}
/**
 * @file: VehicleInspectionReportType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum VehicleInspectionReportType {
  Pre = 'pre',
  Post = 'post'
}

export const VehicleInspectionReportTypeValueSet = new Set([
  VehicleInspectionReportType.Pre,
  VehicleInspectionReportType.Post
]);
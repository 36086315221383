/**
 * @file: Form.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import $ from 'jquery';

$(function() {
  const fields = document.querySelectorAll('.form__picker-field');
  const onFieldClick = (e: Event) => {
    const field = e.target as HTMLDivElement;
    field.style.opacity = '0.5';
    setTimeout(() => {
      field.style.opacity = '1';
    }, 100);
  };
  fields.forEach((field => {
    field.addEventListener('click', onFieldClick);
  }));
});
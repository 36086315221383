/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';

import { withPreviewImages } from 'lib/ui';

export function orderdetail() {
  withPreviewImages('.order__preview-img-btn');
  withPreviewImages('.report-items__list-item-result__media--image');
}
/**
 * @file: OfficialAccountGrantedPermissionType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

export enum OfficialAccountGrantedPermissionType {
  Message = 1,
  User = 2,
  Account = 3,
  WebPage = 4,
  Store = 5,
  MultiServiceSupport = 6,
  MassMessageAndNotification = 7,
  Card = 8,
  Scan = 9,
  WifiConnect = 10,
  Media = 11,
  Shake = 12,
  StoreMiniprogram = 13,
  Menu = 15,
  CertificationStatus = 16,
  MiniprogramAccount = 17,
  MiniprogramDevAnalysis = 18,
  MiniprogramServiceSupportMessage = 19,
  MiniprogramWeixinLogin = 20,
  MiniprogramAnalysis = 21,
  CityServiceApi = 22,
  Ads = 23,
  OpenPlatformAccount = 24,
  MiniprogramOpenPlatformAccount = 25,
  Invoice = 26,
  MiniprogramBasicInfo = 30,
  MiniprogramNearlyPlaces = 37,
  MiniprogramPlugin = 40,
  WidgetSearch = 41,
  MiniprogramCloudDev = 49,
  ServiceAccountConversation = 54
}
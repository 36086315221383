/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import './index.scss';
import $ from 'jquery';
import { takePicture } from 'lib/weixin';
import { spin, unspin } from 'shared';
import { MobileSelect, ActionSheet } from 'lib/ui';
import { InspectionOrderPriority, OcrImageInfo, LicensePlateNoRecognizeInfo, VehicleInfo, VehicleReceptionOrder, InspectionOrderType } from 'model';
import { vehicleInfoService, serviceAgentService } from 'services';
import { VinRecognizeResult, LicensePlateNoRecognizeResult } from 'services/vehicle.service';
import { isLicensePlateNo, isVIN, isMobile } from 'utils/validators';

interface OcrRecognizeResult<T> {
  result: T | null;
  fileName: string;
}

type OcrHelperCallback<T> = (error: Error | null, res: OcrRecognizeResult<T> | null) => void;
type OcrRecognizer<T = any> = (img: OcrImageInfo) => Promise<OcrRecognizeResult<T>>;

const Priorities = [
  { id: InspectionOrderPriority.Normal, value: '普通优先级' },
  { id: InspectionOrderPriority.High, value: '高优先级' },
  { id: InspectionOrderPriority.Urgent, value: '紧急优先级' }
];

const OrderTypes = [
  { id: InspectionOrderType.Basic, value: '基础保养服务' },
  { id: InspectionOrderType.Full, value: '全面保养服务' },
  { id: InspectionOrderType.Repair, value: '维修服务' }
];

class ReceptionOrderManager {
  private orderTypeEl: HTMLElement;
  private captureLicensePlateNoButton: HTMLAnchorElement;
  private captureVinButton: HTMLAnchorElement;
  private licensePlateNoEl: HTMLInputElement;
  private vinEl: HTMLInputElement;
  private modelEl: HTMLInputElement;
  private mileageEl: HTMLInputElement;
  private customerNameEl: HTMLInputElement;
  private customerMobileEl: HTMLInputElement;
  private refreshVinInfoButton: HTMLAnchorElement;
  private vinSpinIconEl: HTMLElement;
  private vehicleNameSelectEl: HTMLElement;
  private priorityEl: HTMLElement;
  private remarkEl: HTMLElement;
  private submitBtn: HTMLButtonElement;
  private priorityPicker: MobileSelect;
  private orderTypePicker: MobileSelect;
  private vehicleNamePicker: MobileSelect | null = null;
  private remarkActionSheet: ActionSheet | null = null;
  private vehicleInfo: Partial<VehicleInfo> | null = null;
  private remark: string | null = null;
  private vinRecognizeResult: VinRecognizeResult | null;
  private licensePlateNoRecognizeResult: LicensePlateNoRecognizeResult | null;
  private priority: InspectionOrderPriority = InspectionOrderPriority.Normal;
  private orderType: InspectionOrderType | null = null;

  // @ts-ignore
  private vinImageInfo: OcrImageInfo | null;

  // @ts-ignore
  private licensePlateNoImageInfo: OcrImageInfo | null;

  init() {
    this.orderTypeEl = document.getElementById('order-type')! as HTMLElement;
    this.captureLicensePlateNoButton = document.getElementById('capture-license-plate-no')! as HTMLAnchorElement;
    this.captureVinButton = document.getElementById('capture-vin')! as HTMLAnchorElement;
    this.licensePlateNoEl = document.getElementById('license-plate-no')! as HTMLInputElement;
    this.vinEl = document.getElementById('vin')! as HTMLInputElement;
    this.modelEl = document.getElementById('model')! as HTMLInputElement;
    this.mileageEl = document.getElementById('mileage')! as HTMLInputElement;
    this.customerNameEl = document.getElementById('customer-name')! as HTMLInputElement;
    this.customerMobileEl = document.getElementById('customer-mobile')! as HTMLInputElement;
    this.refreshVinInfoButton = document.getElementById('refresh-vin-info')! as HTMLAnchorElement;
    this.vinSpinIconEl = document.getElementById('vin-icon-spin')! as HTMLElement;
    this.vehicleNameSelectEl = document.getElementById('select-vehicle-name')! as HTMLElement;
    this.priorityEl = document.getElementById('priority')! as HTMLElement;
    this.remarkEl = document.getElementById('remark')! as HTMLElement;
    this.submitBtn = document.getElementById('btn-submit')! as HTMLButtonElement;

    this.priorityPicker = new MobileSelect({
      trigger: this.priorityEl,
      title: '选择客户工单优先级',
      wheels: [{ data: Priorities }],
      position: [Priorities.findIndex(x => x.id === this.priority)],
      callback: (_, data) => {
        this.priority = data[0].id;
      },
      onShow: () => {
        console.log(this.priorityPicker.getValue());
      }
    });

    this.orderTypePicker = new MobileSelect({
      trigger: this.orderTypeEl,
      title: '选择服务类型',
      wheels: [{ data: OrderTypes }],
      callback: (_, data) => {
        this.orderType = data[0].id;
        this.orderTypeEl.classList.remove('form__picker-field--empty');
      },
      onShow: () => {
        console.log(this.orderTypePicker.getValue());
      }
    });

    this.initEvents();
  }

  initEvents() {
    // license plate number recognize helper
    this.ocrHelper<LicensePlateNoRecognizeInfo>(
      this.captureLicensePlateNoButton,
      (img) => {
        this.licensePlateNoImageInfo = img;
        return vehicleInfoService.licensePlateNoRecognize(img);
      },
      (err, res) => {
        if (err) {
          this.licensePlateNoRecognizeResult = null;
          alert('车牌识别失败: ' + err.message);
          return;
        }
        this.licensePlateNoRecognizeResult = res;
        this.licensePlateNoEl.value = res?.result?.number || '无法识别';
      });

    // VIN recognize helper
    this.ocrHelper<string>(
      this.captureVinButton,
      (img) => {
        this.vinImageInfo = img;
        return vehicleInfoService.vinRecognize(img);
      },
      async (err, res) => {
        if (err) {
          alert('车架号识别失败: ' + err.message);
          return;
        }
        this.vinRecognizeResult = res;
        this.vinEl.value = res?.result || '无法识别';
        if (res?.result) {
          this.loadVinInfo(res?.result);
        }
      });

    this.refreshVinInfoButton.addEventListener('click', this.onRefreshVinInfo);

    this.vinEl.addEventListener('change', () => {
      if (!this.vehicleInfo && this.vinEl.value.trim().length === 17) {
        this.loadVinInfo(this.vinEl.value.trim());
      }
    });

    this.remarkEl.addEventListener('click', this.onShowRemarkActionSheet);

    this.submitBtn.addEventListener('click', this.onSubmit);
  }

  handleSubmitError(e: any) {
    if (e.code === 'authentication_failed') {
      alert('对不起，请重新登录!');
      location.reload();
      return;
    }

    if (e.code === 'access_not_allowed') {
      alert('对不起，您没有权限!');
      return;
    }

    // this should not happend, just in case
    if (e.code === 'resource_not_found') {
      alert('对不起，当前用户信息不存在!');
      return;
    }

    alert('对不起，提交信息失败，请稍候重试!');
  }

  onShowRemarkActionSheet = (e: Event) => {
    e.preventDefault();
    this.remarkActionSheet = new ActionSheet({
      titleBar: {
        cancelButtonText: '取消',
        commitButtonText: '确定',
        title: '服务相关备注'
      },
      contents: this.renderRemarkActionSheetContents(),
      noPadding: true,
      onRender: this.onRemarkActionSheetRendered,
      onDismissed: () => { this.remarkActionSheet = null; },
      onCommit: (containerEl: HTMLElement) => {
        this.remark = (containerEl.querySelector('textarea')! as HTMLTextAreaElement).value.trim();
        this.remarkEl.innerHTML = this.remark || this.remarkEl.getAttribute('data-placeholder')!;
        if (this.remark) {
          this.remarkEl.classList.remove('form__picker-field--empty');
        } else {
          this.remarkEl.classList.add('form__picker-field--empty');
        }
        this.remarkActionSheet?.dismiss();
      },
      onCancel: () => {
        this.remarkActionSheet?.dismiss();
      }
    });

    this.remarkActionSheet.present();
  }

  renderRemarkActionSheetContents() {
    return [
      '<form class="saorder-form__remark-form">',
      `  <textarea class="form-control" rows="8" cols="80" placeholder="输入备注"></textarea>`,
      '</form>'
    ].join('');
  }

  onRemarkActionSheetRendered = (containerEl: HTMLElement) => {
    const textArea = containerEl.querySelector('textarea')! as HTMLTextAreaElement;
    textArea.value = this.remark || '';
  }

  onRefreshVinInfo = (e: Event) => {
    e.preventDefault();
    if (!this.vinEl.value.trim()) return;
    this.loadVinInfo(this.vinEl.value.trim());
  }

  onSubmit = (e: Event) => {
    e.preventDefault();
    const orderInfo = this.getOrderInfo();

    if (!orderInfo) return;

    console.log(orderInfo);

    const actionSheet = new ActionSheet({
      titleBar: {
        title: '核对接车信息',
        cancelButtonText: '返回',
        commitButtonText: '确认'
      },
      noPadding: true,
      contents: this.renderConfirmOrderActionSheetContents(orderInfo),
      onRender: this.onConfirmOrderActionSheetRendered,
      onCommit: () => {
        actionSheet.dismiss(() => {
          this.submitOrder(orderInfo);
        });
      },
      onCancel: () => {
        actionSheet.dismiss();
      }
    });
    actionSheet.present();
  }

  async submitOrder(orderInfo: Partial<VehicleReceptionOrder>) {
    spin();
    try {
      const res = await serviceAgentService.placeOrder(
        orderInfo,
        this.vehicleInfo || undefined
      );
      unspin();
      alert('提交成功!');
      location.href = res.redirectUrl;
    } catch (e) {
      unspin();
      this.handleSubmitError(e);
    }
  }

  private loadVinInfo = async (vin: string) => {
    try {
      this.showVinSpin();
      if (this.vehicleNamePicker) {
        this.vehicleNamePicker.destroy();
        this.vehicleNamePicker = null;
      }

      const vehicleInfo = await vehicleInfoService.getVinInfo(vin);
      if (!vehicleInfo) { return; }

      console.log(vehicleInfo);

      this.vehicleInfo = vehicleInfo;

      this.modelEl.value = vehicleInfo.canonicalName ||
        vehicleInfo.name || '未能获取车辆信息';

      let wheels: any = [];

      if (this.vehicleInfo.relatedCarList) {
        const carlist = JSON.parse(this.vehicleInfo.relatedCarList);
        wheels.push({ data: carlist.map((x: any) => ({ id: x.carid, value: x.name })) });
      } else {
        wheels.push({ data: [{
          id: 0,
          value: this.vehicleInfo.name || this.vehicleInfo.modelName
        }]});
      }

      this.vehicleNamePicker = new MobileSelect({
        trigger: this.vehicleNameSelectEl,
        triggerDisplayData: false,
        title: '选择车型',
        wheels,
        callback: (_, data: any) => {
          this.modelEl.value = data[0].value;
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.hideVinSpin();
    }
  }

  private ocrHelper<T>(
    el: HTMLElement,
    recognize: OcrRecognizer<T>,
    callback: OcrHelperCallback<T>
  ) {
    el.addEventListener('click', async e => {
      e.preventDefault();
      try {
        const result = await takePicture();
        if (!result) {
          alert('拍照失败，请稍后重试!');
          return;
        }

        const img: OcrImageInfo = {
          localId: result.localId,
          mediaId: result.serverId,
          data: result.imageData
        };

        try {
          spin();
          const res = await recognize(img);
          callback(null, res);
        } catch (e) {
          callback(e, null);
        } finally {
          unspin();
        }
      } catch (e) {
        console.error(e);
        alert('拍照失败，请重试!');
      }
    });
  }

  private renderConfirmOrderActionSheetContents(orderInfo: Partial<VehicleReceptionOrder>): string {
    const orderTypeText = OrderTypes.find(x => x.id === orderInfo.orderType!)!.value;
    const priorityText = Priorities.find(x => x.id === orderInfo.priority!)!.value;
    const licensePlateNoImg = this.licensePlateNoImageInfo ? [
      '<a href="#" class="saorder-conform-form__img-preview-btn" data-type="license-plate-no">',
      '  <i class="icon-image"></i>',
      '</a>'
    ].join('') : '';
    const vinImg = this.licensePlateNoImageInfo ? [
      '<a href="#" class="saorder-conform-form__img-preview-btn" data-type="vin">',
      '  <i class="icon-image"></i>',
      '</a>'
    ].join('') : '';
    return [
      '<div class="saorder-conform-form">',
      '  <p class="saorder-conform-form__msg">请核对并确认以下车辆及客户信息，确认无误后提交信息并完成接车。</p>',
      `  <dl><dt>服务类型: </dt><dd>${orderTypeText}</dd></dl>`,
      `  <dl><dt>车牌号: </dt><dd>${orderInfo.licensePlateNo!}${licensePlateNoImg}</dd></dl>`,
      `  <dl><dt>车架号: </dt><dd>${orderInfo.vin}${vinImg}</dd></dl>`,
      `  <dl><dt>车型: </dt><dd>${orderInfo.vehicleName}</dd></dl>`,
      `  <dl><dt>行驶里程: </dt><dd>${orderInfo.vehicleMileage}万公里</dd></dl>`,
      `  <dl><dt>车主姓名: </dt><dd>${orderInfo.customerName}</dd></dl>`,
      `  <dl><dt>车主手机: </dt><dd>${orderInfo.customerMobile || '无'}</dd></dl>`,
      `  <dl><dt>优先级: </dt><dd>${priorityText}</dd></dl>`,
      `  <dl><dt>备注: </dt><dd>${orderInfo.remark || '无'}</dd></dl>`,
      '</div>'
    ].join('');
  }

  private onConfirmOrderActionSheetRendered = (containerEl: HTMLElement) => {
    const licensePlateNoPreviewBtn = containerEl.querySelector(
      '.saorder-conform-form__img-preview-btn[data-type="license-plate-no"]'
      )! as HTMLElement;
    const vinPreviewBtn = containerEl.querySelector(
      '.saorder-conform-form__img-preview-btn[data-type="vin"]'
    )! as HTMLElement;
    licensePlateNoPreviewBtn && licensePlateNoPreviewBtn.addEventListener('click', () => {
      wx.previewImage({
        current: this.licensePlateNoImageInfo?.localId,
        urls: [this.licensePlateNoImageInfo?.localId, this.vinImageInfo?.localId].filter(x => x)
      });
    });
    vinPreviewBtn && vinPreviewBtn.addEventListener('click', () => {
      wx.previewImage({
        current: this.vinImageInfo?.localId,
        urls: [this.licensePlateNoImageInfo?.localId, this.vinImageInfo?.localId].filter(x => x)
      });
    });
  }

  private showVinSpin() {
    $('#refresh-vin-info').show();
    $('#vin-icon-select').hide();
    this.vinSpinIconEl.classList.add('icon-pulse');
  }

  private hideVinSpin() {
    $('#refresh-vin-info').hide();
    if (this.vehicleNamePicker) {
      $('#vin-icon-select').show();
    }
    this.vinSpinIconEl.classList.remove('icon-pulse');
  }

  private getOrderInfo(): Partial<VehicleReceptionOrder | null> {
    const orderType = this.orderType;
    const licensePlateNo = this.licensePlateNoEl.value.trim().toUpperCase();
    const vin = this.vinEl.value.trim().toUpperCase();
    const vehicleName = this.modelEl.value.trim();
    const mileage = this.mileageEl.value.trim();
    const customerName = this.customerNameEl.value.trim();
    const customerMobile = this.customerMobileEl.value.trim();
    const priority = this.priority;
    const remark = this.remark;

    let errMsg: string = '';
    if (!orderType) errMsg = '请选择服务类型!';
    else if (!licensePlateNo) errMsg = '请输入或识别车牌!';
    else if (!isLicensePlateNo(licensePlateNo)) errMsg = '车牌号码格式有误!';
    else if (!vin) errMsg = '请输入或识别车架号!';
    else if (!isVIN(vin)) errMsg = '车架号格式有误!';
    else if (!vehicleName) errMsg = '请输入或选择车型!'
    else if (!mileage) errMsg = '请输入车辆行驶里程!';
    else if (!/^[0-9]+(\.[0-9]+)?/.test(mileage)) errMsg = '请输入正确的里程数!';
    else if (!customerName) errMsg = '请输入客户姓名!';
    else if (customerMobile && !isMobile(customerMobile)) errMsg = '客户手机号码格式有误!';

    if (errMsg) {
      alert(errMsg);
      return null;
    }

    return {
      orderType: orderType!,
      vin,
      vinImgUrl: this.vinRecognizeResult && this.vinRecognizeResult.fileName,
      licensePlateNo,
      licensePlateNoImgUrl: this.licensePlateNoRecognizeResult && this.licensePlateNoRecognizeResult.fileName,
      vehicleName,
      vehicleMileage: Number(mileage),
      customerName,
      customerMobile,
      priority,
      remark
    };
  }
}

export function saorder() {
  const manager = new ReceptionOrderManager();
  manager.init();
}